import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { generatePath } from 'react-router-dom'; 
import history from '../history';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faBars } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {

  if (window.location.pathname.includes("cankado") || window.location.pathname.includes("logout")) {
    return null;
  }

  return (
    <footer className="site-footer mt-4">
      <Navbar expand="lg" className='inesc-bg-dk-green inesc-border-md-green'>
        <Container>
          <Navbar.Brand href="/">
            <img
              src="/assets/INESCTEC_logo_green.jpg"
              width="272"
              height="82"
              className="d-inline-block align-top img-fluid"
              alt="INESCTEC Logo"
            />
          </Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/about"))}>About</Nav.Link>
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/contact"))}>Contact Us</Nav.Link>
            </Nav>
        </Container>
      </Navbar>
    </footer>
  );
};

export default Footer;
