import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container';
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import Col from "react-bootstrap/esm/Col";
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const TeamsPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const [appState, setAppState] = useState({
        isLoading: true,
        teams: {},
    })


    let getTeams = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/teams/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setAppState({
                isLoading: false,
                teams: data,
            })
        }
    }

    useEffect(() => {
        getTeams()
    }, [])

    if (appState.isLoading === true) {
        return (
            <Container className='text-center'>
                <div style={{ marginTop: '22.5%', }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Container>
        )
    } else {
        return (
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Teams Page
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Container className=''>


                    <div className='addPatient-div'>
                        <Button variant="outline-primary" className="addPatient-button mx-4" onClick={() => history.push(generatePath("/teams/create"))}><i className="fas fa-user-group"></i> Create Team</Button>
                    </div>
                    <Row xs={1} md={3} className='my-4 justify-content-center'>
                        {
                            Object.keys(appState.teams).length !== 0 ?
                                Object.entries(appState.teams).map(team => {
                                    const [name, info] = team
                                    return (
                                        <Card key={name} className="mx-auto my-2 teamCard">
                                            <Card.Body className='text-center'>
                                                <Card.Title as="h3">
                                                    {name}
                                                </Card.Title>
                                                <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + info.image_path} height="150" />
                                                <Card.Text>
                                                    Number of Users: {info.num_users}
                                                </Card.Text>
                                                <Card.Text>
                                                    Number of Patients: {info.num_patients}
                                                </Card.Text>
                                                <Container className='text-center'>
                                                    <Button className="addPatient-button" variant="outline-primary" onClick={() => history.push({ pathname: generatePath("/team/:team_name", { team_name: name }), state: name })}><i className="fa-solid fa-circle-info" /> Details</Button>
                                                </Container>
                                            </Card.Body>
                                        </Card>
                                    )
                                })
                                :
                                (<p>You have no teams!</p>)
                        }
                    </Row>
                </Container >

            </Container>

        )
    }

}

export default withRouter(TeamsPage)