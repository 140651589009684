import React, { useContext, useEffect } from 'react'
import AuthContext from '../context/AuthContext';
import LoginPage from './LoginPage';
import PatientsPage from './PatientsPage';
import history from '../history';
import { withRouter, generatePath } from "react-router-dom";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode"
import { Container } from 'react-bootstrap';

const LogoutPage = () => {
    let { updateToken, user, isAuthenticated, logoutUser, authTokens  } = useContext(AuthContext)

    localStorage.removeItem('authTokens')

    return(
        <Container className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center'}}>
            <h3>You have been logged out of the BreloAI platform!</h3>
        </Container> 
    )
}

export default withRouter(LogoutPage)
