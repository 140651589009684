import './App.css';
import React from 'react'
import { Router, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext'
import history from './history'

import Container from 'react-bootstrap/esm/Container';
import Header from './components/Navbar';
import Footer from './components/Footer';
import PatientsPage from './pages/PatientsPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import RegisterPage from './pages/RegisterPage';
import AddPatientPage from './pages/AddPatientPage';
import PatientProfilePage from './pages/PatientProfilePage';
import UpdatePatientPage from './pages/UpdatePatientPage';
import UploadPhotosPage from './pages/UploadPhotosPage';
import PatientPhotosPage from './pages/PatientPhotosPage';
import DashboardPage from './pages/DashboardPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import UserProfilePage from './pages/UserProfilePage';
import UpdateUserPage from './pages/UpdateUserPage';
import TeamCreatePage from './pages/TeamCreatePage';
import TeamsPage from './pages/TeamsPage';
import TeamDetailsPage from './pages/TeamDetailsPage';
import BcctCorePage from './pages/BcctCorePage';
import InteractionsPage from './pages/InteractionsPage';
import CinderellaPage from './pages/CinderellaPage';
import WebBCCTCorePage from './pages/WebBCCTCorePage';
import BcctCoreCankadoPage from './pages/BcctCoreCankadoPage';
import CataloguePage from './pages/CataloguePage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import NippleAnnotationPage from './pages/NippleAnnotationPage';
import LoginPageChampalimaud from './pages/LoginPageChampalimaud';
import CreateSurgeryPage from './pages/CreateSurgeryPage';
import CreateRadiotherapyPage from './pages/CreateRadiotherapyPage';
import MorphingPage from './pages/MorphingPage';
import EditPatientPage from './pages/EditPatientPage';

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <div className='content-wrapper'>
          <AuthProvider>
            {window.location.pathname.includes("cankado") ? null : <Header />}
            <Route exact component={BcctCoreCankadoPage} path="/patient/:id/:date/:photo_id/cankado/bcctcore" />
            <Container className='main-body'>
              <Route exact component={HomePage} path="/" />
              <Route exact component={LoginPage} path="/login" />
              <Route exact component={LogoutPage} path="/logout" />
              <Route exact component={PatientsPage} path="/:username/patients" />
              <Route exact component={RegisterPage} path="/register" />
              <Route exact component={AddPatientPage} path="/:username/addPatient" />
              <Route exact component={PatientProfilePage} path="/patient/:id" />
              <Route exact component={EditPatientPage} path="/patient/:id/edit/:id2" />
              <Route exact component={UploadPhotosPage} path="/patient/:id/upload" />
              <Route exact component={PatientPhotosPage} path="/patient/:id/photographs/:date" />
              <Route exact component={BcctCorePage} path="/patient/:id/:date/:photo_id/bcctcore" />
              <Route exact component={NippleAnnotationPage} path="/patient/:id/:date/:photo_id/nipple" />
              <Route exact component={DashboardPage} path="/:username/dashboard" />
              <Route exact component={AboutPage} path="/about" />
              <Route exact component={ContactPage} path="/contact" />
              <Route exact component={UserProfilePage} path="/:username/profile" />
              <Route exact component={UpdateUserPage} path="/:username/profile/edit" />
              <Route exact component={TeamCreatePage} path="/teams/create" />
              <Route exact component={TeamsPage} path="/teams" />
              <Route exact component={TeamDetailsPage} path="/team/:team_name" />
              <Route exact component={InteractionsPage} path="/patient/:id/:date/photos/:number/interactions" />
              <Route exact component={CinderellaPage} path="/breloai" />
              <Route exact component={WebBCCTCorePage} path="/webbcctcore" />
              <Route exact component={CataloguePage} path="/catalogue" />
              <Route exact component={MorphingPage} path="/morphing" />
              <Route exact component={ResetPasswordPage} path="/resetPassword/:id/:token" />
              <Route exact component={LoginPageChampalimaud} path="/champalimaud/login" />
              <Route exact component={CreateSurgeryPage} path="/patient/:id/addSurgery" />
              <Route exact component={CreateRadiotherapyPage} path="/patient/:id/addRadiotherapy" />
            </Container>
          </AuthProvider>
        </div>
        {window.location.pathname.includes("cankado") ? null : <Footer />}
      </Router>
    </div>
  );
}

export default App;
