import React, { useContext, useEffect, useState } from 'react'
import { withRouter, useLocation, generatePath } from "react-router-dom"
import AuthContext from '../context/AuthContext';
import history from '../history';

import axios from 'axios';

import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/esm/Col";
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { IconCheckbox } from "react-icon-checkbox";

import { FileUploader } from "react-drag-drop-files";
import Compressor from 'compressorjs';

import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select'

const fileTypes = ["JPG", "PNG", "jpeg", "png", "jpg", "tiff", "tif", "bmp"];

const UploadPhotosPage = () => {
    let { authTokens, user, url } = useContext(AuthContext)
    const location = useLocation()

    let institutional_number = location.state.patient !== undefined ? location.state.patient.institutional_number : location.state.institutional_number
    let id = location.state.patient !== undefined ? location.state.patient.id : location.state.id
    let page_info = location.state.page !== undefined ? location.state.page : null

    const [errors, setErrors] = useState({})

    const [isActive, setIsActive] = useState(false)
    const [showToast, setShowToast] = useState(false);

    const [cancerSide, setCancerSide] = useState([])
    const [braSize, setBraSize] = useState("")
    const [braCup, setBraCup] = useState("")
    const [weight, setWeight] = useState(0)
    const [height, setHeight] = useState(0)

    const options4 = [
        { value: "1", label: 'Left' },
        { value: "2", label: 'Right' },
    ]

    const [favorites, setFavorites] = useState([])

    let createPhotohraphMoment = async (e) => {
        e.preventDefault()

        setIsActive(true)

        let data = new FormData(); // creates a new FormData object
        let i = 0
        files.forEach((file) => {
            data.append("image_" + i, file);
            i = i + 1
        })
        if (location.state.date == null || location.state.date == undefined) {
            data.append("date", date)
            data.append("weight", weight)
            data.append("height", height)
            data.append("bra_size", braSize)
            data.append("bra_cup", braCup)
            data.append("edit_moment", false)
            data.append("favorites", favorites)
        }
        else {
            data.append("edit_moment", true)
            data.append("date", location.state.date)
        }


        axios.post('https://breloai-backend.inesctec.pt/api/patient/' + id + '/add/photographicMoment', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
            .then(res => {
                history.push({ pathname: generatePath("/patient/:id", { id: id }), state: { id: id } })
            })
            .catch(err => {
                if (err.response.data['acquisition_date']) {
                    setIsActive(false)
                    setErrors({ acquisition_date: err.response.data['acquisition_date'] })
                }
                else if (err.response.data['images']) {
                    setIsActive(false)
                    setErrors({ images: err.response.data['images'] })
                }
                else {
                    setIsActive(false)
                    setShowToast(true)
                }
            }
            )
    }

    const [files, setFiles] = useState([]);
    const [compressed_files, setCompressedFiles] = useState([]);

    const handleChange = async (files) => {
        let favorites_aux = []
        for (let index = 0; index < files.length; index++) {
            favorites_aux.push(false)
        }
        setFavorites(favorites_aux)
        await setFiles(Array.from(files))
        setErrors({})
    };

    const [date, setDate] = useState(location.state.date ? location.state.date : undefined)
    const updateDate = (date) => {
        setDate(date)
        setErrors({})
    }

    let handleDelete = async (file) => {
        favorites.splice(files.indexOf(file), 1)
        let newFiles = files.filter((x) => x.name !== file.name)
        await setFiles(newFiles)
    }

    let updateFavorite = (index) => {
        const updatedFavorites = [...favorites];
        for (let i = 0; i < updatedFavorites.length; i++) {
            if (i === index) {
                if (updatedFavorites[i] === true) {
                    updatedFavorites[i] = false
                }
                else {
                    updatedFavorites[i] = true
                }
            }
            else {
                updatedFavorites[i] = false
            }
        }
        setFavorites(updatedFavorites)
    }

    return (
        <Container className='px-4 my-2'>

            <Breadcrumb>
                {page_info === null ? <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item> : page_info.source === "home" ? <Breadcrumb.Item onClick={() => { history.push({ pathname: generatePath("/:username/patients", { username: user.username }), state: { page_info: page_info } }) }}>Patients</Breadcrumb.Item> : page_info.source === "dashboard" ? <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/:username/dashboard", { username: user.username }), state: { page_info: page_info } })}>Dashboard</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>}
                <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/patient/:id", { id: id }), state: { id: id } })}>
                    Patient {institutional_number} Profile
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Add Patient {institutional_number} Photograph Moment
                </Breadcrumb.Item>
            </Breadcrumb>

            <h1> Add Patient {institutional_number} Photograph Moment</h1>

            <Container>
                <ToastContainer className="mt-5" position="bottom-center">
                    <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide bg='danger'>
                        <Toast.Header>
                            <strong className="me-auto">Communication with database failed!</strong>
                        </Toast.Header>
                        <Toast.Body>
                            The photos weren't uploaded!
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                {
                    location.state.date === undefined ?
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Height</Form.Label>
                                        <Form.Control type="text" name={"height"} onChange={(e) => setHeight(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Weight</Form.Label>
                                        <Form.Control type="text" name={"weight"} onChange={(e) => setWeight(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className='mb-1'>
                                        <Form.Label>Bra Size</Form.Label>
                                        <Form.Control name={"bra_size"} id="bra_size" type="text" onChange={(e) => setBraSize(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col style={{ height: "100%" }}>
                                    <Form.Group className='mb-1'>
                                        <Form.Label>Bra Cup</Form.Label>
                                        <Form.Control name={"bra_cup"} id="bra_cup" type="text" onChange={(e) => setBraCup(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                        :
                        null
                }

                <LoadingOverlay active={isActive} spinner text='Uploading Photos...'>
                    <Form className='' onSubmit={createPhotohraphMoment}>
                        <Row>
                            <Col>
                                <Form.Group className='my-2'>
                                    <Form.Label>Upload a group of photographs</Form.Label>
                                    <FileUploader multiple={true} handleChange={handleChange} name="file" types={fileTypes} />
                                    <p className='error-feedback'>{errors.images ? errors.images : null}</p>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='my-2'>
                                    <Form.Label>Acquisition date of the photographs</Form.Label>
                                    <Form.Control name="acquisition_date" onChange={(e) => updateDate(e.target.value)} type="date" isInvalid={!!errors.acquisition_date} value={location.state.date ? location.state.date : undefined} />
                                    <Form.Control.Feedback type="invalid">{errors.acquisition_date}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row xs={1} md={3} className='g-2 my-3'>
                            {
                                files.length !== 0 ? (
                                    [...files].map((file, index) => {
                                        return (
                                            <div key={file.name}>
                                                <Card>
                                                    <Card.Img variant="top" src={URL.createObjectURL(file)} width="250" height="250" />
                                                    <Card.ImgOverlay className='addPatient-div' style={{ fontSize: "1.8rem", height: '2vh' }}>
                                                        <IconCheckbox checked={favorites[files.indexOf(file)]}
                                                            checkedIcon={<i className="fa-solid fa-heart" style={{ color: 'red' }} aria-hidden="true"></i>}
                                                            uncheckedIcon={<i className="fa-regular fa-heart" style={{ color: 'red' }} aria-hidden="true"></i>}
                                                            onClick={() => updateFavorite(index)}
                                                        />
                                                    </Card.ImgOverlay>
                                                    <Card.Body className='text-center'>
                                                        <Card.Title>
                                                            {file.name}
                                                        </Card.Title>
                                                        <Button variant='outline-danger' onClick={() => handleDelete(file)}>Delete</Button>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )
                                    }
                                    )
                                )
                                    :
                                    (<p>No images selected</p>)
                            }
                        </Row>
                        <Form.Group className="text-center">
                            <Button className='FilledButton' type="submit">Upload</Button>
                        </Form.Group>

                    </Form>
                </LoadingOverlay>
            </Container>
        </Container >

    )
}

export default withRouter(UploadPhotosPage)