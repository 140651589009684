import React, { useContext, useEffect, useState } from 'react'
import { withRouter, useLocation, generatePath } from "react-router-dom"
import AuthContext from '../context/AuthContext';
import history from '../history';

import axios from 'axios';

import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/esm/Col";
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { FileUploader } from "react-drag-drop-files";
import Compressor from 'compressorjs';

import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select'

const CreateRadiotherapyPage = () => {
    let { authTokens, user, url } = useContext(AuthContext)
    const location = useLocation()

    let institutional_number = location.state.patient !== undefined ? location.state.patient.institutional_number : location.state.institutional_number
    let id = location.state.patient !== undefined ? location.state.patient.id : location.state.id
    let page_info = location.state.page !== undefined ? location.state.page : null
    let surgery_date = location.state.surgery_date !== undefined ? location.state.surgery_date : null

    const [errors, setErrors] = useState({})

    const [rt_gray_total_dose, setTotalDose] = useState(null)
    const [rt_boost_total_dose, setBoostTotalDose] = useState(null)

    const [startDate, setStartDate] = useState(undefined)
    const updateStartDate = (date) => {
        setStartDate(date)
        setErrors({})
    }

    const [endDate, setEndDate] = useState(undefined)
    const updateEndDate = (date) => {
        setEndDate(date)
        setErrors({})
    }

    let createRadiotherapy = async () => {

        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + id + '/add/radiotherapy', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: JSON.stringify({
                'had_radiotherapy': true,
                "rt_start_date": startDate,
                "rt_end_date": endDate,
                "rt_gray_total_dose": rt_gray_total_dose,
                "rt_boost_total_dose": rt_boost_total_dose
            })
        })
        let data = await response
        if (response.status === 200) {
            history.push({ pathname: generatePath("/patient/:id", { id: id }), state: { id: id } })
        }
        else if (response.status == 400) {
            setErrors({ date: response.data['date'] })
        }
    }

    return (
        <Container className='px-4 my-2'>

            <Breadcrumb>
                {page_info === null ? <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item> : page_info.source === "home" ? <Breadcrumb.Item onClick={() => { history.push({ pathname: generatePath("/:username/patients", { username: user.username }), state: { page_info: page_info } }) }}>Patients</Breadcrumb.Item> : page_info.source === "dashboard" ? <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/:username/dashboard", { username: user.username }), state: { page_info: page_info } })}>Dashboard</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>}
                <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/patient/:id", { id: id }), state: { id: id } })}>
                    Patient {institutional_number} Profile
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Add Radiotherapy Patient {institutional_number}
                </Breadcrumb.Item>
            </Breadcrumb>

            <h1> Add Radiotherapy Patient {institutional_number}</h1>

            <Container>

                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>Radiotherapy Start Date</Form.Label>
                        <Form.Control name={"surgery_date"} type="date" onChange={(e) => updateStartDate(e.target.value)} />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>Radiotherapy End Date</Form.Label>
                        <Form.Control name={"surgery_date"} type="date" onChange={(e) => updateEndDate(e.target.value)} />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Label style={{ color: "#444444" }} htmlFor="editRtTotalDose" as="h4"><strong>Radiotherapy Total Dose:</strong></Form.Label>
                    <Form.Control className='my-3' style={{ maxWidth: '100%' }} type='text' id='editRtTotalDose' defaultValue={null} onChange={(e) => { setTotalDose(e.target.value) }} />
                </Col>

                <Col>
                    <Form.Label style={{ color: "#444444" }} htmlFor="editRtTotalDose" as="h4"><strong>Radiotherapy Boost Total Dose:</strong></Form.Label>
                    <Form.Control className='my-3' style={{ maxWidth: '100%' }} type='text' id='editRtTotalDose' defaultValue={null} onChange={(e) => {setBoostTotalDose(e.target.value) }} />
                </Col>


                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button className='FilledButton' onClick={createRadiotherapy} >Submit</Button>
                </Col>
            </Container>
        </Container >

    )
}

export default withRouter(CreateRadiotherapyPage)