import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container';
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const TeamCreatePage = () => {
    let { authTokens, url } = useContext(AuthContext)
    const [errors, setErrors] = useState({})

    const [team_name, setTeamName] = useState("")

    const handleChange = (e) => {
        setTeamName(e.target.value.trim())
        setErrors({})
    };

    const createTeam = async (e) => {
        e.preventDefault()
        let response = await fetch('https://breloai-backend.inesctec.pt/api/teams/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: JSON.stringify({ 'team_name': team_name, })
        })
        let data = await response.json()
        if (response.status === 200) {
            history.push("/teams")
        }
        else if (response.status === 400 && data.team_name) {
            setErrors({ team_name: data.team_name })
        }
    }

    return (
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/teams"))}>Teams Page</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Create Team Page
                </Breadcrumb.Item>
            </Breadcrumb>
            
            <Container className='content-container text-center'>
                <h2 className='my-3'> Create a new <span className='text-primary-color'>team</span></h2>

                <Container className='my-4'>
                    <Form onSubmit={createTeam}>
                        <Form.Group>
                            <Form.Label>Write a name for the Team:</Form.Label>
                            <Form.Control name="team_name" className='mx-auto text-center' type="text" placeholder="Team Name" style={{ marginBottom: 0, marginTop: "2vh", width: "50%", }} onChange={handleChange} isInvalid={!!errors.team_name}></Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.team_name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='text-center my-4'>
                            <Button variant="outline-primary" className="addPatient-button mx-4" type='submit'>Create</Button>
                        </Form.Group>
                    </Form>
                </Container>

            </Container>
        </Container>

    )
}

export default withRouter(TeamCreatePage)