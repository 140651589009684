import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthContext'
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';
import { useLocation } from "react-router-dom";

import Container from 'react-bootstrap/esm/Container'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const UpdatePatientPage = () => {
  let { authTokens, url } = useContext(AuthContext)
  const [errors, setErrors] = useState({})

  const location = useLocation()
  let info = location.state.info
  let is_cinderella = location.state.is_cinderella

  const [radiotherapy, setRadiotherapy] = useState(info.had_radiotherapy ? info.had_radiotherapy : false)
  const [radiotherapyBoost, setRadiotherapyBoost] = useState(info.had_rt_boost ? info.had_rt_boost : false)
  const [cinderella, setCinderella] = useState(is_cinderella)

  const initialFormData = Object.freeze({
    institutional_number: info.institutional_number, birthday: info.birthday, bra_size: info.bra_size, bra_cup: info.bra_cup, cancer_side: info.cancer_sides, height: info.patient_height, weight: info.patient_weight, surgery_date: info.surgery_date, start_date: info.rt_start_date, end_date: info.rt_end_date, total_dose: info.rt_gray_total_dose, surgery_type: info.surgery_type,
  });

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  let updatePatient = async (e) => {
    e.preventDefault()
    let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + info.id + '/update', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authTokens?.access
      },
      body: JSON.stringify({
        'institutional_number': e.target.institutional_number.value,
        'birthday': e.target.birthday.value,
        'bra_size': e.target.bra_size.value,
        'bra_cup': e.target.bra_cup.value,
        'cancer_side': e.target.cancer_side.value,
        'height': e.target.height.value,
        'weight': e.target.weight.value,
        'surgery_date': e.target.surgery_date.value,
        'start_date': radiotherapy ? e.target.start_date.value : null,
        'end_date': radiotherapy ? e.target.end_date.value : null,
        'total_dose': radiotherapy ? e.target.total_dose.value : null,
        'radiotherapy': radiotherapy,
        'radiotherapy_boost': radiotherapyBoost,
        'surgery_type': e.target.surgery_type.value,
        'is_cinderella': cinderella,
      })
    })
    let data = await response
    if (response.status === 200) {
      history.push({ pathname: generatePath("/patient/:id", { id: info.id }), state: { id: info.id} })
    }
    else if (response.status === 400 && data.institutional_number) {
      setErrors({ institutional_number: data.institutional_number })
    }
    else if (response.status === 400 && data.birthday) {
      setErrors({ birthday: data.birthday })
    }

  }

  return (
    <div className='addPatient-form-container my-auto'>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
        <Breadcrumb.Item  onClick={() => history.push({ pathname: generatePath("/patient/:id", { id: info.id }), state: {id: info.id} })}>
          Patient {info.institutional_number} Profile
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          Edit Patient {info.institutional_number} Information
        </Breadcrumb.Item>
      </Breadcrumb>

      <span>Update Patient {info.institutional_number} Information</span>
      <Form className='addPatientForm' onSubmit={updatePatient}>
        <Form.Group className="addPatient">
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Institutional Number</Form.Label>
                <Form.Control name="institutional_number" type="text" style={{ marginBottom: 0 }} onChange={handleChange} isInvalid={!!errors.institutional_number} defaultValue={info.institutional_number} />
                <Form.Control.Feedback type="invalid">{errors.institutional_number}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Birthday</Form.Label>
                <Form.Control name="birthday" type="date" style={{ marginBottom: 0 }} onChange={handleChange} isInvalid={!!errors.birthday} defaultValue={info.birthday} />
                <Form.Control.Feedback type="invalid">{errors.birthday}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Bra Size</Form.Label>
                <Form.Control name="bra_size" type="text" onChange={handleChange} defaultValue={info.bra_size} />
              </Form.Group>
            </Col>
            <Col style={{ height: "100%" }}>
              <Form.Group className='mb-3'>
                <Form.Label>Bra Cup</Form.Label>
                <Form.Control name="bra_cup" type="text" onChange={handleChange} defaultValue={info.bra_cup} />
                <Form.Text className="text-muted" style={{ fontSize: "0.8vw" }}>Please note that WebBCCT.core uses the European convention for bra sizes.Click <a href="#">here</a> to know how to convert your unit to the European unit.</Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row style={{ width: "50%" }}>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Cancer Side</Form.Label>
                <Form.Select name="cancer_side" defaultValue={info.cancer_sides} onChange={handleChange}>
                  <option value="null">---------</option>
                  <option value="1">Left</option>
                  <option value="2">Right</option>
                  <option value="3">Bilateral</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Height</Form.Label>
                <Form.Control type="text" name="height" onChange={handleChange} defaultValue={info.patient_height} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Weight</Form.Label>
                <Form.Control type="text" name="weight" onChange={handleChange} defaultValue={info.patient_weight} />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Surgery Type</Form.Label>
                <Form.Select name="surgery_type" defaultValue={info.surgery_type} onChange={handleChange}>
                  <option value="null">---------</option>
                  <option value="1">C1 - Conservative Surgery</option>
                  <option value="2">C2 - Conservative Surgery with bilateral reduction</option>
                  <option value="3">C3 - Conservative Surgery with LD or LICAC/TDAP flaps</option>
                  <option value="4">C4 - Conservative Surgery with bilateral implants</option>
                  <option value="5">M1 - Mastectomy with unilateral reconstruction with implant</option>
                  <option value="6">M2 - Mastectomy with unilateral reconstruction with autologous flap</option>
                  <option value="7">M3 - Mastectomy with bilateral reconstruction with implants</option>
                  <option value="8">M4 - Mastectomy with bilateral reconstruction with autologous flaps</option>
                  <option value="9">M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)</option>
                  <option value="10">M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction</option>
                  <option value="11">M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction</option>
                  <option value="12">M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>Surgery Date</Form.Label>
                <Form.Control name="surgery_date" type="date" onChange={handleChange} defaultValue={info.surgery_date} />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className='mb-3'>
            <Form.Check type="checkbox" checked={radiotherapy} name="radiotherapy" label="Radiotherapy?" onClick={() => { setRadiotherapy(current => !current); }} />
          </Form.Group>

          {radiotherapy ?
            <Form.Group>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Radiotherapy Start Date</Form.Label>
                    <Form.Control name="start_date" type="date" onChange={handleChange} defaultValue={info.rt_start_date} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Radiotherapy End Date</Form.Label>
                    <Form.Control name="end_date" type="date" onChange={handleChange} defaultValue={info.rt_end_date} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Radiotherapy Total Dose (Gy)</Form.Label>
                    <Form.Control type="text" name="total_dose" onChange={handleChange} defaultValue={info.rt_gray_total_dose} />
                  </Form.Group>
                </Col>
                <Col className='my-auto'>
                  <Form.Group className='mb-3'>
                    <Form.Check type="checkbox" checked={radiotherapyBoost} name="radiotherapy_boost" label="Radiotherapy Boost?" onClick={() => { setRadiotherapyBoost(current => !current) }} />
                  </Form.Group>
                </Col>
              </Row>
            </Form.Group>
            : null}

          <Form.Group className='mb-3'>
            <Form.Check type="checkbox" name="cinderella" label="Cinderella?" onClick={() => { setCinderella(current => !current); }} defaultChecked={is_cinderella} />
          </Form.Group>

          <Form.Group className='text-center'>
            <Button className="addPatient-Submit-Button" variant="primary" type="submit">Update</Button>

          </Form.Group>
        </Form.Group>
      </Form>
    </div>
  )
}

export default withRouter(UpdatePatientPage)
