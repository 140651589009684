import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import { Card, Row, Col } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Collapsible from 'react-collapsible';
import { ListManager } from 'react-beautiful-dnd-grid';
import LoadingOverlay from 'react-loading-overlay';
import Switch from "react-switch";

const MorphingPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isActive, setIsActive] = useState(false)

    const [toggleMorphing, setToggleMorphing] = useState(false)
    const handleToggleMorphing = () => {
        setToggleMorphing(!toggleMorphing)
    }

    const [showToast, setShowToast] = useState(false);

    const fullPage = document.querySelector('#fullpage');

    const setImageFullScreen = (img) => {
        fullPage.style.backgroundImage = 'url(' + img.src + ')';
        fullPage.style.display = 'block';
    }

    const closeImageFullScreen = () => {
        fullPage.style.display = 'none';
    }

    const [query_patient_image, setQuery_patient_image] = useState(null)
    const [template_patient_image, setTemplate_patient_image] = useState(null)
    const [morphed_image_helena, setMorphed_image_helena] = useState(null)
    const [morphed_image_nuno, setMorphed_image_nuno] = useState(null)

    let getImages = async () => {
        setIsActive(true)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/compareMorphing/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            console.log(data)
            setQuery_patient_image(data['query_patient_image'])
            setTemplate_patient_image(data['template_patient_image'])
            setMorphed_image_helena(data['morphed_image_helena'])
            setMorphed_image_nuno(data['morphed_image_nuno'])
        }
        else {
            console.log("ups")
        }
        setIsActive(false)
    }

    return (
        <Container style={{ minWidth: '100vw' }}>
            <div id="fullpage" onClick={() => closeImageFullScreen()}></div>

            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Morphing Page
                </Breadcrumb.Item>
            </Breadcrumb>

            <ToastContainer className="mt-5" position="top-center">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg='success'>
                    <Toast.Header>
                        <strong className="me-auto">Download Successful</strong>
                    </Toast.Header>
                    <Toast.Body>
                        Go to the '/media/csvs' in the CINDERELLA NAS.
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {
                user.is_staff ?
                    <Container>
                        <div className='addPatient-div'>
                            <Button variant="outline-primary" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-download" aria-hidden="true"></i> Download Morphing Data</Button>
                        </div>
                    </Container> :
                    null
            }

            <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                <Button variant="outline-primary"  className="addPatient-button morphingButton" onClick={() => getImages()}><i className="fa fa-check" aria-hidden="true"></i> Fetch</Button>
            </div>
            <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                <Card className="text-center queryCardMorphing" style={{ width: '20vw' }}>
                    <Card.Header style={{ backgroundColor: "white" }}>Query Patient</Card.Header>
                    <Card.Img onClick={(e) => setImageFullScreen(e.target)} variant="top" src={query_patient_image === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + query_patient_image} width="250" height="250" />
                </Card>
            </div>

            <LoadingOverlay active={isActive} spinner>
                <Row className="responsiveRow">
                    <Col>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                <Card.Header style={{ backgroundColor: "white" }}>Algorithm 1</Card.Header>
                                <Card.Img onClick={(e) => setImageFullScreen(e.target)} variant="top" src={query_patient_image === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt/media/default.jpeg'} width="250" height="250" />
                            </Card>
                        </Row>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Button variant="outline-primary morphingButton" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                        </Row>
                    </Col>
                    <Col>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                <Card.Header style={{ backgroundColor: "white" }}>Algorithm 2</Card.Header>
                                <Card.Img onClick={(e) => setImageFullScreen(e.target)} variant="top" src={morphed_image_helena === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt/' + morphed_image_helena} width="250" height="250" />
                            </Card>
                        </Row>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Button variant="outline-primary morphingButton" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                        </Row>
                    </Col>
                    <Col>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                <Card.Header style={{ backgroundColor: "white" }}>Algorithm 3</Card.Header>
                                <Card.Img onClick={(e) => setImageFullScreen(e.target)} variant="top" src={morphed_image_nuno === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt/' + morphed_image_nuno} width="250" height="250" />
                            </Card>
                        </Row>
                        <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            <Button variant="outline-primary morphingButton" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                        </Row>
                    </Col>
                </Row>
            </LoadingOverlay>

        </Container >

    )
}

export default withRouter(MorphingPage)