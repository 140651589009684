import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container';
import history from '../history';
import { withRouter, generatePath, useLocation } from "react-router-dom";
import axios from 'axios'
import AuthContext from '../context/AuthContext';

import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/esm/Image';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import LoadingOverlay from 'react-loading-overlay';
import Collapsible from 'react-collapsible';

import * as createjs from 'createjs-module';

import detect from 'detect.js'


const BcctCoreCankadoPage = () => {
    let { isAuthenticated, authTokens, user, logoutUser, url } = useContext(AuthContext)
    var smoothArrayJs = require("smooth-array-js")

    const location = useLocation()

    var patient_id = location.state != undefined ? location.state.patient_id : location.pathname.split('/')[2]
    var p_image_id = location.state != undefined ? location.state.photo.id : location.pathname.split('/')[4]
    var photo_date = location.pathname.split('/')[3]
    let page_info = location.state != undefined ? location.state.page : null
    let is_pre_surgery = location.state != undefined ? location.state.is_pre_surgery : true

    const [isActive, setIsActive] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false);
    const [communicationError, setCommunicationError] = useState(false)

    var canvas, stage;
    //var update = true;
    const [update, setUpdate] = useState(true)
    var nMarkers = 38;

    // Indices of the Keypoints in the UI
    var leftNippleIdx = 0;
    var rightNippleIdx = 1;
    var markScaleIdx = 2;
    var sternalNotchIdx = 3;
    var leftContourIdxStart = 4;
    var leftContourIdxEnd = 20;
    var rightContourIdxStart = 21;
    var rightContourIdxEnd = 37;

    //
    const [markersVec, set_markersVec] = useState([])
    var markersText = ["Left Nipple", "Right Nipple", "Scale Mark", "Sternal Notch"];
    var contours;
    var backgroundImg;
    var bitmap_bck;
    var loaded_img;
    var container;
    var BRA;
    var txtBRA;
    var zoomStatus = 0;
    const [photo, setPhoto] = useState([])

    const [bra_value, set_bra_value] = useState(null)
    const [lbc_value, set_lbc_value] = useState(null)
    const [unr_value, set_unr_value] = useState(null)
    const [bce_value, set_bce_value] = useState(null)
    const [bcd_value, set_bcd_value] = useState(null)
    const [bad_value, set_bad_value] = useState(null)
    const [bod_value, set_bod_value] = useState(null)
    const [p_bra_value, set_p_bra_value] = useState(null)
    const [p_lbc_value, set_p_lbc_value] = useState(null)
    const [p_unr_value, set_p_unr_value] = useState(null)
    const [p_bce_value, set_p_bce_value] = useState(null)
    const [p_bcd_value, set_p_bcd_value] = useState(null)
    const [p_bad_value, set_p_bad_value] = useState(null)
    const [p_bod_value, set_p_bod_value] = useState(null)
    const [cx2l_value, set_cx2l_value] = useState(null)
    const [cx2a_value, set_cx2a_value] = useState(null)
    const [cx2b_value, set_cx2b_value] = useState(null)
    const [cx2lab_value, set_cx2lab_value] = useState(null)
    const [cemdl_value, set_cemdl_value] = useState(null)
    const [cemda_value, set_cemda_value] = useState(null)
    const [cemdb_value, set_cemdb_value] = useState(null)
    const [cemdlab_value, set_cemdlab_value] = useState(null)
    const [classification_value, set_classification_value] = useState(null)
    const [scale_value, set_scale_value] = useState(null)


    let sx2l_value = null
    let sx2a_value = null
    let sx2b_value = null
    let sx2lab_value = null
    let semdl_value = null
    let semda_value = null
    let semdb_value = null
    let semdlab_value = null
    let l_breast_contour_len = null
    let r_breast_contour_len = null
    let l_breast_area = null
    let r_breast_area = null
    let l_nipple_ifdni = null
    let r_nippple_ifdni = null
    let l_nipple_hpsn_x1 = null
    let r_nipple_hpsn_x2 = null
    let l_nipple_vpsn_y1 = null
    let r_nipple_vpsn_y2 = null

    const [refresh, setRefresh] = useState(false)
    const [firstRequest, setFirstRequest] = useState(true)

    //const [cankadoDetails, setCankadoDetails] = useState(null)
    let cankadoDetails = null
    const [cankadoAccessToken, setCankadoAccessToken] = useState(null)

    let institutional_number = null

    var browser_info = detect.parse(navigator.userAgent);

    //const aestheticClassificationValue = ReactDOM.createRoot(document.getElementById('aestheticClassificationValue'));

    const start = async () => {
        logInCankado()
        if (firstRequest) {
            await axios.get('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + photo_date + '/' + p_image_id + '/bcctcore', {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + authTokens?.access,
                }
            }).then(res => {
                photo.push(res.data.data)
                institutional_number = res.data.institutional_number
                set_bra_value(res.data.data['bra_value'].toFixed(2))
                set_lbc_value(res.data.data['lbc_value'].toFixed(2))
                set_unr_value(res.data.data['unr_value'].toFixed(2))
                set_bce_value(res.data.data['bce_value'].toFixed(2))
                set_bcd_value(res.data.data['bcd_value'].toFixed(2))
                set_bad_value(res.data.data['bad_value'].toFixed(2))
                set_bod_value(res.data.data['bod_value'].toFixed(2))
                set_p_bra_value(res.data.data['p_bra_value'].toFixed(2))
                set_p_lbc_value(res.data.data['p_lbc_value'].toFixed(2))
                set_p_unr_value(res.data.data['p_unr_value'].toFixed(2))
                set_p_bce_value(res.data.data['p_bce_value'].toFixed(2))
                set_p_bcd_value(res.data.data['p_bcd_value'].toFixed(2))
                set_p_bad_value(res.data.data['p_bad_value'].toFixed(2))
                set_p_bod_value(res.data.data['p_bod_value'].toFixed(2))
                set_cx2l_value(res.data.data['cx2l_value'].toFixed(2))
                set_cx2a_value(res.data.data['cx2a_value'].toFixed(2))
                set_cx2b_value(res.data.data['cx2b_value'].toFixed(2))
                set_cx2lab_value(res.data.data['cx2lab_value'].toFixed(2))
                set_cemdl_value(res.data.data['cemdl_value'].toFixed(2))
                set_cemda_value(res.data.data['cemda_value'].toFixed(2))
                set_cemdb_value(res.data.data['cemdb_value'].toFixed(2))
                set_cemdlab_value(res.data.data['cemdlab_value'].toFixed(2))
                set_classification_value(res.data.data['classification_value'])
                set_scale_value(res.data.data['scale_value'].toFixed(2))
                return res.data.data
            }).then((res) => {
                /*if (browser_info.browser.family !== "Safari") {
                    init(res)
                }*/
                setRefresh(true)
                init(res)
            }).catch((error) => {
                if (error.response.status == 500) {
                    setCommunicationError(true)
                    setRefresh(true)
                }
            })
        }
        setFirstRequest(false)
    }

    const init = (photo) => {
        canvas = document.getElementById("bcctCanvas")

        stage = new createjs.Stage(canvas);
        // enable touch interactions if supported on the current device:
        createjs.Touch.enable(stage);
        // enabled mouse over / out events
        stage.enableMouseOver(10);
        stage.mouseMoveOutside = true; // keep tracking the mouse even when it leaves the canvas
        loaded_img = false;
        container = new createjs.Container();

        for (var i = markersText.length; i < nMarkers; i++) {
            if (i == leftContourIdxEnd) {
                markersText.push("Left EP");
            }
            else if (i == leftContourIdxStart) {
                markersText.push("Left MP");
            }
            else if (i == rightContourIdxStart) {
                markersText.push("Right EP");
            }
            else if (i == rightContourIdxEnd) {
                markersText.push("Right MP");
            }
            else {
                markersText.push("");
            }
        }


        stage.addChild(container);

        container.removeAllChildren();

        bitmap_bck = new createjs.Bitmap("https://breloai-backend.inesctec.pt" + photo.image)
        container.addChild(bitmap_bck);
        bitmap_bck.originalScale = 1.0;
        bitmap_bck.scale = 1.0;

        canvas.height = bitmap_bck.image.height * bitmap_bck.originalScale;
        canvas.width = bitmap_bck.image.width * bitmap_bck.originalScale;

        txtBRA = new createjs.Text();
        txtBRA.font = "bold 12px Arial";
        txtBRA.color = "#000000";
        txtBRA.textAlign = "left";
        txtBRA.text = "BRA";
        txtBRA.x = 4;
        txtBRA.y = 4;

        if (markersVec.length != 0) {
            markersVec.length = 0
        }
        // create and populate the screen with markers:
        for (var i = 0; i < nMarkers; i++) {
            markersVec.push(new createjs.Container());
            var shapeMarker = new createjs.Shape();
            var g = shapeMarker.graphics;
            g.beginFill("#FF0000");
            shapeMarker.alpha = 0.25;
            if (((i > leftContourIdxStart) && (i < leftContourIdxEnd)) || (i > rightContourIdxStart) && (i < rightContourIdxEnd)) {
                g.beginFill("#DFDFDF");
                shapeMarker.alpha = 0.5;
            }
            var radius = canvas.height / 40;
            g.drawCircle(0, 0, radius);
            g.endFill();

            markersVec[i].addChild(shapeMarker);
            shapeMarker = new createjs.Shape();
            g = shapeMarker.graphics;
            g.beginFill("#000000");
            g.drawCircle(0, 0, 1);
            g.endFill();
            markersVec[i].addChild(shapeMarker);

            container.addChild(markersVec[i]);
            markersVec[i].scale = markersVec[i].originalScale = 1;//Math.random() * 0.4 + 0.6;
            markersVec[i].name = "bmp_" + i;
            markersVec[i].cursor = "pointer";

            var txt = new createjs.Text();
            txt.font = "bold 5vh Dorsa";
            txt.color = "#000000";
            txt.textAlign = "center";
            txt.text = markersText[i];
            markersVec[i].addChild(txt);

            // using "on" binds the listener to the scope of the currentTarget by default
            // in this case that means it executes in the scope of the button.
            markersVec[i].on("mousedown", function (evt) {
                this.parent.addChild(this);
                this.offset = { x: this.x - evt.stageX, y: this.y - evt.stageY };
            });
            // the pressmove event is dispatched when the mouse moves after a mousedown on the target until the mouse is released.
            markersVec[i].on("pressmove", function (evt) {
                this.x = evt.stageX + this.offset.x;
                this.y = evt.stageY + this.offset.y;
                // indicate that the stage should be updated on the next tick:
                //update = true;
                setUpdate(true)
            });
            markersVec[i].on("rollover", function (evt) {
                this.scale = this.originalScale * 1.2;
                //update = true;
                setUpdate(true)
            });
            markersVec[i].on("rollout", function (evt) {
                this.scale = this.originalScale;
                //update = true;
                setUpdate(true)
                savePointsDB("savePoint")
            });
        }

        // Scale Mark
        markersVec[markScaleIdx].x = photo.scale_mark_x === null ? 0.5 * canvas.width : photo.scale_mark_x;
        markersVec[markScaleIdx].y = photo.scale_mark_y === null ? 2.0 * canvas.height / 3 : photo.scale_mark_y;

        markersVec[sternalNotchIdx].x = photo.sternal_notch_x
        markersVec[sternalNotchIdx].y = photo.sternal_notch_y

        markersVec[leftNippleIdx].x = photo.left_nipple_x
        markersVec[leftNippleIdx].y = photo.left_nipple_y

        markersVec[rightNippleIdx].x = photo.right_nipple_x
        markersVec[rightNippleIdx].y = photo.right_nipple_y

        markersVec[rightContourIdxStart].x = photo.right_endpoint_x
        markersVec[rightContourIdxStart].y = photo.right_endpoint_y

        markersVec[rightContourIdxStart + 1].x = photo.r_breast_contour_19_x
        markersVec[rightContourIdxStart + 1].y = photo.r_breast_contour_19_y

        markersVec[rightContourIdxStart + 2].x = photo.r_breast_contour_20_x
        markersVec[rightContourIdxStart + 2].y = photo.r_breast_contour_20_y

        markersVec[rightContourIdxStart + 3].x = photo.r_breast_contour_21_x
        markersVec[rightContourIdxStart + 3].y = photo.r_breast_contour_21_y

        markersVec[rightContourIdxStart + 4].x = photo.r_breast_contour_22_x
        markersVec[rightContourIdxStart + 4].y = photo.r_breast_contour_22_y

        markersVec[rightContourIdxStart + 5].x = photo.r_breast_contour_23_x
        markersVec[rightContourIdxStart + 5].y = photo.r_breast_contour_23_y

        markersVec[rightContourIdxStart + 6].x = photo.r_breast_contour_24_x
        markersVec[rightContourIdxStart + 6].y = photo.r_breast_contour_24_y

        markersVec[rightContourIdxStart + 7].x = photo.r_breast_contour_25_x
        markersVec[rightContourIdxStart + 7].y = photo.r_breast_contour_25_y

        markersVec[rightContourIdxStart + 8].x = photo.r_breast_contour_26_x
        markersVec[rightContourIdxStart + 8].y = photo.r_breast_contour_26_y

        markersVec[rightContourIdxStart + 9].x = photo.r_breast_contour_27_x
        markersVec[rightContourIdxStart + 9].y = photo.r_breast_contour_27_y

        markersVec[rightContourIdxStart + 10].x = photo.r_breast_contour_28_x
        markersVec[rightContourIdxStart + 10].y = photo.r_breast_contour_28_y

        markersVec[rightContourIdxStart + 11].x = photo.r_breast_contour_29_x
        markersVec[rightContourIdxStart + 11].y = photo.r_breast_contour_29_y

        markersVec[rightContourIdxStart + 12].x = photo.r_breast_contour_30_x
        markersVec[rightContourIdxStart + 12].y = photo.r_breast_contour_30_y

        markersVec[rightContourIdxStart + 13].x = photo.r_breast_contour_31_x
        markersVec[rightContourIdxStart + 13].y = photo.r_breast_contour_31_y

        markersVec[rightContourIdxStart + 14].x = photo.r_breast_contour_32_x
        markersVec[rightContourIdxStart + 14].y = photo.r_breast_contour_32_y

        markersVec[rightContourIdxStart + 15].x = photo.r_breast_contour_33_x
        markersVec[rightContourIdxStart + 15].y = photo.r_breast_contour_33_y

        markersVec[rightContourIdxEnd].x = photo.right_midpoint_x
        markersVec[rightContourIdxEnd].y = photo.right_midpoint_y

        markersVec[leftContourIdxStart].x = photo.left_midpoint_x
        markersVec[leftContourIdxStart].y = photo.left_midpoint_y

        markersVec[leftContourIdxStart + 1].x = photo.l_breast_contour_2_x
        markersVec[leftContourIdxStart + 1].y = photo.l_breast_contour_2_y

        markersVec[leftContourIdxStart + 2].x = photo.l_breast_contour_3_x
        markersVec[leftContourIdxStart + 2].y = photo.l_breast_contour_3_y

        markersVec[leftContourIdxStart + 3].x = photo.l_breast_contour_4_x
        markersVec[leftContourIdxStart + 3].y = photo.l_breast_contour_4_y

        markersVec[leftContourIdxStart + 4].x = photo.l_breast_contour_5_x
        markersVec[leftContourIdxStart + 4].y = photo.l_breast_contour_5_y

        markersVec[leftContourIdxStart + 5].x = photo.l_breast_contour_6_x
        markersVec[leftContourIdxStart + 5].y = photo.l_breast_contour_6_y

        markersVec[leftContourIdxStart + 6].x = photo.l_breast_contour_7_x
        markersVec[leftContourIdxStart + 6].y = photo.l_breast_contour_7_y

        markersVec[leftContourIdxStart + 7].x = photo.l_breast_contour_8_x
        markersVec[leftContourIdxStart + 7].y = photo.l_breast_contour_8_y

        markersVec[leftContourIdxStart + 8].x = photo.l_breast_contour_9_x
        markersVec[leftContourIdxStart + 8].y = photo.l_breast_contour_9_y

        markersVec[leftContourIdxStart + 9].x = photo.l_breast_contour_10_x
        markersVec[leftContourIdxStart + 9].y = photo.l_breast_contour_10_y

        markersVec[leftContourIdxStart + 10].x = photo.l_breast_contour_11_x
        markersVec[leftContourIdxStart + 10].y = photo.l_breast_contour_11_y

        markersVec[leftContourIdxStart + 11].x = photo.l_breast_contour_12_x
        markersVec[leftContourIdxStart + 11].y = photo.l_breast_contour_12_y

        markersVec[leftContourIdxStart + 12].x = photo.l_breast_contour_13_x
        markersVec[leftContourIdxStart + 12].y = photo.l_breast_contour_13_y

        markersVec[leftContourIdxStart + 13].x = photo.l_breast_contour_14_x
        markersVec[leftContourIdxStart + 13].y = photo.l_breast_contour_14_y

        markersVec[leftContourIdxStart + 14].x = photo.l_breast_contour_15_x
        markersVec[leftContourIdxStart + 14].y = photo.l_breast_contour_15_y

        markersVec[leftContourIdxStart + 15].x = photo.l_breast_contour_16_x
        markersVec[leftContourIdxStart + 15].y = photo.l_breast_contour_16_y

        markersVec[leftContourIdxEnd].x = photo.left_endpoint_x
        markersVec[leftContourIdxEnd].y = photo.left_endpoint_y


        contours = new createjs.Shape();
        container.addChild(contours);

        loaded_img = true;
        //update = true;
        setUpdate(true)

        document.getElementById("refreshCanva").addEventListener("click", () => refreshCanva())
        createjs.Ticker.addEventListener("tick", tick);

        document.getElementById("myRange3").addEventListener("mouseup", (e) => updateMarkerSizes())

        BRA = 0;
    }

    function updateMarkerSizes() {
        markersVec.forEach(marker => {
            marker.children[0].scaleX = document.getElementById("myRange3").value / 2.5
            marker.children[0].scaleY = document.getElementById("myRange3").value / 2.5
            marker.children[1].scaleX = document.getElementById("myRange3").value
            marker.children[1].scaleY = document.getElementById("myRange3").value
            marker.children[2].font = "bold " + document.getElementById("myRange3").value * 2.5 + "vh Dorsa"
        })
        setUpdate(true)
    }

    function tick(event) {
        // this set makes it so the stage only re-renders when an event handler indicates a change has happened.
        if (update) {
            //update = false; // only update once
            setUpdate(false)
            drawContour();
            stage.update(event);
        }
    }

    function drawContour() {
        var spline_res = 64;
        contours.graphics.clear();
        contours.graphics.setStrokeStyle(document.getElementById("myRange3").value * 3).beginStroke("rgba(0,0,0,1)");

        var points = [];
        for (var i = leftContourIdxStart; i <= leftContourIdxEnd; i++) {
            var aux_ = [];
            aux_.push(markersVec[i].x);
            aux_.push(markersVec[i].y);
            points.push(aux_);
        }
        var path = smoothArrayJs.Smooth(points, {
            method: smoothArrayJs.Smooth.METHOD_CUBIC,
            cubicTension: smoothArrayJs.Smooth.CUBIC_TENSION_CATMULL_ROM
        });

        var aux2 = path(0);
        contours.graphics.moveTo(aux2[0], aux2[1]);
        for (var i = 0; i <= points.length - 1; i += (1.0 / spline_res)) {
            aux2 = path(i);
            contours.graphics.lineTo(aux2[0], aux2[1]);
        }


        points = [];
        for (var i = rightContourIdxStart; i <= rightContourIdxEnd; i++) {
            var aux = [];
            aux.push(markersVec[i].x);
            aux.push(markersVec[i].y);
            points.push(aux);
        }
        path = smoothArrayJs.Smooth(points, {
            method: smoothArrayJs.Smooth.METHOD_CUBIC,
            cubicTension: smoothArrayJs.Smooth.CUBIC_TENSION_CATMULL_ROM
        });

        var aux = path(0);
        contours.graphics.moveTo(aux[0], aux[1]);
        for (var i = 0; i <= points.length - 1; i += (1.0 / spline_res)) {
            aux = path(i);
            contours.graphics.lineTo(aux[0], aux[1]);
        }


        contours.graphics.endStroke();
    }

    var zoom = (factor) => {
        if (!loaded_img)
            return;
        // if (zoomStatus == 0 && factor < 1.0) {
        // return;
        // }
        bitmap_bck.scale *= factor;
        bitmap_bck.scaleX *= factor;
        bitmap_bck.scaleY *= factor;
        canvas.height = bitmap_bck.image.height * bitmap_bck.scale;
        canvas.width = bitmap_bck.image.width * bitmap_bck.scale;
        //change pos of markers
        for (var i = 0; i < nMarkers; i++) {
            markersVec[i].x *= factor;
            markersVec[i].y *= factor;
        }
        //update = true;
        setUpdate(true)
        if (factor < 1.0) {
            zoomStatus -= 1;
        } else if (factor > 1.0) {
            zoomStatus += 1;
        } else {
            zoomStatus += 0;
        }
    }

    var returnToInitialZoomState = () => {
        /*var factor = null
        if (zoomStatus < 1.0) {
            factor = Math.pow(2.0, Math.abs(zoomStatus));
        } else {
            factor = Math.pow(0.5, Math.abs(zoomStatus));
        }*/

        canvas.height = bitmap_bck.image.height;
        canvas.width = bitmap_bck.image.width;

        markersVec.forEach(marker => {
            marker.children[0].scaleX = 200 / 2.5
            marker.children[0].scaleY = 200 / 2.5
            marker.children[1].scaleX = 200
            marker.children[1].scaleY = 200
            marker.children[2].font = "bold " + 2 * 2.5 + "vh Dorsa"
        })

        //update = true;
        setUpdate(true)
        zoomStatus = 0;
    }

    // Function: Print several values to the JavaScript console (TODO: Erase this uppon review)
    function logmarkers() {
        console.log(markersVec[sternalNotchIdx].x, markersVec[sternalNotchIdx].y);
        console.log(markersVec[leftNippleIdx].x, markersVec[leftNippleIdx].y);
        console.log(markersVec[rightContourIdxStart].x, markersVec[rightContourIdxStart].y);
        console.log(markersVec[rightContourIdxEnd].x, markersVec[rightContourIdxEnd].y);
        console.log(markersVec[leftContourIdxEnd].x, markersVec[leftContourIdxEnd].y);
        console.log(zoomStatus);
    }

    function stop() {
        createjs.Ticker.removeEventListener("tick", tick);
    }

    const style = (node, styles) => Object.keys(styles).forEach(key => node.style[key] = styles[key])

    var refreshCanva = () => {
        document.getElementById("myRange3").value = 2
        //start()
        //if (browser_info.browser.family !== "Safari"){
        let canvas = document.getElementById("bcctCanvas")
        let overlay = canvas.parentElement
        canvas.setAttribute("id", "bcctCanvasOld")
        canvas.setAttribute("hidden", "")

        const newCanvas = document.createElement("canvas")
        newCanvas.setAttribute("id", "bcctCanvas")
        style(newCanvas, { border: "1px solid #000000", maxWidth: "100%" })


        overlay.appendChild(newCanvas)
        init(photo[0])
        //}
    }

    useEffect(() => {
        if (window.parent == window) {
            logoutUser(1)
        }
        else {
            start()
            if (refresh) {
                var reloading = sessionStorage.getItem("reloading");
                if (reloading) {
                    if (!communicationError) {
                        let refresh_action = setTimeout(() => {
                            refreshCanva()
                        }, 1000)
                    }
                    sessionStorage.removeItem("reloading");
                }
                else {
                    sessionStorage.setItem("reloading", "true");
                    let refresh_action = setTimeout(() => {
                        window.location.reload(true)
                    }, 750)
                }
                setRefresh(false)
            }
        }

    }, [refresh, communicationError])

    async function savePointsDB(action) {

        var factor = null
        if (zoomStatus < 1.0) {
            factor = Math.pow(2.0, Math.abs(zoomStatus));
        } else {
            factor = Math.pow(0.5, Math.abs(zoomStatus));
        }
        // let keypoints = Object.assign({}, markersVec);

        let keypoints = {}; // objCopy will store a copy of the mainObj
        let key;

        for (key in markersVec) {
            keypoints[key] = markersVec[key]; // copies each property to the objCopy object
        }

        /*bitmap_bck.scale *= factor;
        bitmap_bck.scaleX *= factor;
        bitmap_bck.scaleY *= factor;
        canvas.height = bitmap_bck.image.height * bitmap_bck.scaleY;
        canvas.width = bitmap_bck.image.width * bitmap_bck.scaleX;*/

        for (var i = 0; i < nMarkers; i++) {
            keypoints[i].x *= factor;
            keypoints[i].y *= factor;
        }

        // Scale Mark
        let scale_mark_x = keypoints[markScaleIdx].x;
        let scale_mark_y = keypoints[markScaleIdx].y;

        // Sternal Notch
        let sternal_notch_x = keypoints[sternalNotchIdx].x;
        let sternal_notch_y = keypoints[sternalNotchIdx].y;

        // Left Nipple
        let right_nipple_x = keypoints[rightNippleIdx].x;
        let right_nipple_y = keypoints[rightNippleIdx].y;

        // Right Nipple
        let left_nipple_x = keypoints[leftNippleIdx].x;
        let left_nipple_y = keypoints[leftNippleIdx].y;



        // Right Endpoint
        let right_endpoint_x = keypoints[rightContourIdxStart].x;
        let right_endpoint_y = keypoints[rightContourIdxStart].y;

        // Right Breast Contour
        let r_breast_contour_19_x = keypoints[rightContourIdxStart + 1].x;
        let r_breast_contour_19_y = keypoints[rightContourIdxStart + 1].y;

        let r_breast_contour_20_x = keypoints[rightContourIdxStart + 2].x;
        let r_breast_contour_20_y = keypoints[rightContourIdxStart + 2].y;

        let r_breast_contour_21_x = keypoints[rightContourIdxStart + 3].x;
        let r_breast_contour_21_y = keypoints[rightContourIdxStart + 3].y;

        let r_breast_contour_22_x = keypoints[rightContourIdxStart + 4].x;
        let r_breast_contour_22_y = keypoints[rightContourIdxStart + 4].y;

        let r_breast_contour_23_x = keypoints[rightContourIdxStart + 5].x;
        let r_breast_contour_23_y = keypoints[rightContourIdxStart + 5].y;

        let r_breast_contour_24_x = keypoints[rightContourIdxStart + 6].x;
        let r_breast_contour_24_y = keypoints[rightContourIdxStart + 6].y;

        let r_breast_contour_25_x = keypoints[rightContourIdxStart + 7].x;
        let r_breast_contour_25_y = keypoints[rightContourIdxStart + 7].y;

        let r_breast_contour_26_x = keypoints[rightContourIdxStart + 8].x;
        let r_breast_contour_26_y = keypoints[rightContourIdxStart + 8].y;

        let r_breast_contour_27_x = keypoints[rightContourIdxStart + 9].x;
        let r_breast_contour_27_y = keypoints[rightContourIdxStart + 9].y;

        let r_breast_contour_28_x = keypoints[rightContourIdxStart + 10].x;
        let r_breast_contour_28_y = keypoints[rightContourIdxStart + 10].y;

        let r_breast_contour_29_x = keypoints[rightContourIdxStart + 11].x;
        let r_breast_contour_29_y = keypoints[rightContourIdxStart + 11].y;

        let r_breast_contour_30_x = keypoints[rightContourIdxStart + 12].x;
        let r_breast_contour_30_y = keypoints[rightContourIdxStart + 12].y;

        let r_breast_contour_31_x = keypoints[rightContourIdxStart + 13].x;
        let r_breast_contour_31_y = keypoints[rightContourIdxStart + 13].y;

        let r_breast_contour_32_x = keypoints[rightContourIdxStart + 14].x;
        let r_breast_contour_32_y = keypoints[rightContourIdxStart + 14].y;

        let r_breast_contour_33_x = keypoints[rightContourIdxStart + 15].x;
        let r_breast_contour_33_y = keypoints[rightContourIdxStart + 15].y;

        // Right Breast Midpoint
        let right_midpoint_x = keypoints[rightContourIdxEnd].x;
        let right_midpoint_y = keypoints[rightContourIdxEnd].y;



        // Left Midpoint
        let left_midpoint_x = keypoints[leftContourIdxStart].x;
        let left_midpoint_y = keypoints[leftContourIdxStart].y;

        // Left Breast Contour
        let l_breast_contour_2_x = keypoints[leftContourIdxStart + 1].x;
        let l_breast_contour_2_y = keypoints[leftContourIdxStart + 1].y;

        let l_breast_contour_3_x = keypoints[leftContourIdxStart + 2].x;
        let l_breast_contour_3_y = keypoints[leftContourIdxStart + 2].y;

        let l_breast_contour_4_x = keypoints[leftContourIdxStart + 3].x;
        let l_breast_contour_4_y = keypoints[leftContourIdxStart + 3].y;

        let l_breast_contour_5_x = keypoints[leftContourIdxStart + 4].x;
        let l_breast_contour_5_y = keypoints[leftContourIdxStart + 4].y;

        let l_breast_contour_6_x = keypoints[leftContourIdxStart + 5].x;
        let l_breast_contour_6_y = keypoints[leftContourIdxStart + 5].y;

        let l_breast_contour_7_x = keypoints[leftContourIdxStart + 6].x;
        let l_breast_contour_7_y = keypoints[leftContourIdxStart + 6].y;

        let l_breast_contour_8_x = keypoints[leftContourIdxStart + 7].x;
        let l_breast_contour_8_y = keypoints[leftContourIdxStart + 7].y;

        let l_breast_contour_9_x = keypoints[leftContourIdxStart + 8].x;
        let l_breast_contour_9_y = keypoints[leftContourIdxStart + 8].y;

        let l_breast_contour_10_x = keypoints[leftContourIdxStart + 9].x;
        let l_breast_contour_10_y = keypoints[leftContourIdxStart + 9].y;

        let l_breast_contour_11_x = keypoints[leftContourIdxStart + 10].x;
        let l_breast_contour_11_y = keypoints[leftContourIdxStart + 10].y;

        let l_breast_contour_12_x = keypoints[leftContourIdxStart + 11].x;
        let l_breast_contour_12_y = keypoints[leftContourIdxStart + 11].y;

        let l_breast_contour_13_x = keypoints[leftContourIdxStart + 12].x;
        let l_breast_contour_13_y = keypoints[leftContourIdxStart + 12].y;

        let l_breast_contour_14_x = keypoints[leftContourIdxStart + 13].x;
        let l_breast_contour_14_y = keypoints[leftContourIdxStart + 13].y;

        let l_breast_contour_15_x = keypoints[leftContourIdxStart + 14].x;
        let l_breast_contour_15_y = keypoints[leftContourIdxStart + 14].y;

        let l_breast_contour_16_x = keypoints[leftContourIdxStart + 15].x;
        let l_breast_contour_16_y = keypoints[leftContourIdxStart + 15].y;

        // Left Breast Endpoint
        let left_endpoint_x = keypoints[leftContourIdxEnd].x;
        let left_endpoint_y = keypoints[leftContourIdxEnd].y;



        // Create a dictionary with this data
        var keypoint_data = {
            'patient_id': patient_id,
            'p_image_id': p_image_id,
            'scale_mark_x': scale_mark_x,
            'scale_mark_y': scale_mark_y,
            'sternal_notch_x': sternal_notch_x,
            'sternal_notch_y': sternal_notch_y,
            'right_nipple_x': right_nipple_x,
            'right_nipple_y': right_nipple_y,
            'left_nipple_x': left_nipple_x,
            'left_nipple_y': left_nipple_y,
            'right_midpoint_x': right_midpoint_x,
            'right_midpoint_y': right_midpoint_y,
            'right_endpoint_x': right_endpoint_x,
            'right_endpoint_y': right_endpoint_y,
            'left_endpoint_x': left_endpoint_x,
            'left_endpoint_y': left_endpoint_y,
            'left_midpoint_x': left_midpoint_x,
            'left_midpoint_y': left_midpoint_y,
            'r_breast_contour_19_x': r_breast_contour_19_x,
            'r_breast_contour_19_y': r_breast_contour_19_y,
            'r_breast_contour_20_x': r_breast_contour_20_x,
            'r_breast_contour_20_y': r_breast_contour_20_y,
            'r_breast_contour_21_x': r_breast_contour_21_x,
            'r_breast_contour_21_y': r_breast_contour_21_y,
            'r_breast_contour_22_x': r_breast_contour_22_x,
            'r_breast_contour_22_y': r_breast_contour_22_y,
            'r_breast_contour_23_x': r_breast_contour_23_x,
            'r_breast_contour_23_y': r_breast_contour_23_y,
            'r_breast_contour_24_x': r_breast_contour_24_x,
            'r_breast_contour_24_y': r_breast_contour_24_y,
            'r_breast_contour_25_x': r_breast_contour_25_x,
            'r_breast_contour_25_y': r_breast_contour_25_y,
            'r_breast_contour_26_x': r_breast_contour_26_x,
            'r_breast_contour_26_y': r_breast_contour_26_y,
            'r_breast_contour_27_x': r_breast_contour_27_x,
            'r_breast_contour_27_y': r_breast_contour_27_y,
            'r_breast_contour_28_x': r_breast_contour_28_x,
            'r_breast_contour_28_y': r_breast_contour_28_y,
            'r_breast_contour_29_x': r_breast_contour_29_x,
            'r_breast_contour_29_y': r_breast_contour_29_y,
            'r_breast_contour_30_x': r_breast_contour_30_x,
            'r_breast_contour_30_y': r_breast_contour_30_y,
            'r_breast_contour_31_x': r_breast_contour_31_x,
            'r_breast_contour_31_y': r_breast_contour_31_y,
            'r_breast_contour_32_x': r_breast_contour_32_x,
            'r_breast_contour_32_y': r_breast_contour_32_y,
            'r_breast_contour_33_x': r_breast_contour_33_x,
            'r_breast_contour_33_y': r_breast_contour_33_y,
            'l_breast_contour_2_x': l_breast_contour_2_x,
            'l_breast_contour_2_y': l_breast_contour_2_y,
            'l_breast_contour_3_x': l_breast_contour_3_x,
            'l_breast_contour_3_y': l_breast_contour_3_y,
            'l_breast_contour_4_x': l_breast_contour_4_x,
            'l_breast_contour_4_y': l_breast_contour_4_y,
            'l_breast_contour_5_x': l_breast_contour_5_x,
            'l_breast_contour_5_y': l_breast_contour_5_y,
            'l_breast_contour_6_x': l_breast_contour_6_x,
            'l_breast_contour_6_y': l_breast_contour_6_y,
            'l_breast_contour_7_x': l_breast_contour_7_x,
            'l_breast_contour_7_y': l_breast_contour_7_y,
            'l_breast_contour_8_x': l_breast_contour_8_x,
            'l_breast_contour_8_y': l_breast_contour_8_y,
            'l_breast_contour_9_x': l_breast_contour_9_x,
            'l_breast_contour_9_y': l_breast_contour_9_y,
            'l_breast_contour_10_x': l_breast_contour_10_x,
            'l_breast_contour_10_y': l_breast_contour_10_y,
            'l_breast_contour_11_x': l_breast_contour_11_x,
            'l_breast_contour_11_y': l_breast_contour_11_y,
            'l_breast_contour_12_x': l_breast_contour_12_x,
            'l_breast_contour_12_y': l_breast_contour_12_y,
            'l_breast_contour_13_x': l_breast_contour_13_x,
            'l_breast_contour_13_y': l_breast_contour_13_y,
            'l_breast_contour_14_x': l_breast_contour_14_x,
            'l_breast_contour_14_y': l_breast_contour_14_y,
            'l_breast_contour_15_x': l_breast_contour_15_x,
            'l_breast_contour_15_y': l_breast_contour_15_y,
            'l_breast_contour_16_x': l_breast_contour_16_x,
            'l_breast_contour_16_y': l_breast_contour_16_y,
        };

        for (var i = 0; i < nMarkers; i++) {
            keypoints[i].x /= factor;
            keypoints[i].y /= factor;
        }

        /*bitmap_bck.scale /= factor;
        bitmap_bck.scaleX /= factor;
        bitmap_bck.scaleY /= factor;
        canvas.height = bitmap_bck.image.height / bitmap_bck.scaleY;
        canvas.width = bitmap_bck.image.width / bitmap_bck.scaleX;*/

        let accessToken = localStorage.getItem('authTokens')
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + p_image_id + '/updateKeyPoints', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken.split('"')[3],
            },
            body: JSON.stringify(keypoint_data),
        }).then(result => {
            sendLogsCankado(action, result.status)
        })


    }

    // Function: Show the classification value
    function showAestheticClassification(props) {
        const classification_value = props.classification_value;
        if (classification_value == 0) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Unknown</h5></li>;
        } else if (classification_value == 1) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Excellent</h5></li>;
        } else if (classification_value == 2) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Good</h5></li>;
        } else if (classification_value == 3) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Fair</h5></li>;
        } else if (classification_value == 4) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Poor</h5></li>;
        }
    }

    // TODO: Function: Refine the breast contour keypoints using the Dijkstra Shortest-Path Algorithm
    async function refineKeypoints() {
        setIsActive(true)
        var factor = null
        if (zoomStatus < 1.0) {
            factor = Math.pow(2.0, Math.abs(zoomStatus));
        } else {
            factor = Math.pow(0.5, Math.abs(zoomStatus));
        }

        let keypoints = {}; // objCopy will store a copy of the mainObj
        let key;

        for (key in markersVec) {
            keypoints[key] = markersVec[key]; // copies each property to the objCopy object
        }

        /*for (var i = 0; i < nMarkers; i++) {
            keypoints[i].x *= factor;
            keypoints[i].y *= factor;
        }*/

        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + p_image_id + '/refineKeyPoints', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
        })
        let data = await response.json()
        if (response.status == 200) {
            for (let i = 0; i < data.left_contour.length; i++) {
                markersVec[leftContourIdxStart + i].x = data.left_contour[i][1]
                markersVec[leftContourIdxStart + i].y = data.left_contour[i][0]
            }
            for (let i = 0; i < data.right_contour.length; i++) {
                markersVec[rightContourIdxStart + i].x = data.right_contour[i][1]
                markersVec[rightContourIdxStart + i].y = data.right_contour[i][0]
            }
            savePointsDB("refineKeypoints")
            for (var i = 4; i < markersVec.length; i++) {
                markersVec[i].x *= factor;
                markersVec[i].y *= factor;
            }
            //update = true;
            setUpdate(true)
            setIsActive(false)
        }
    }

    // Function: Compute the features and get the aesthetic classification of a given image 
    let getClassification = async (image) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + p_image_id + '/classification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: image,
        })
        let data = await response.json()
        if (response.status === 200) {
            set_bra_value(data['bra_value'].toFixed(2))
            set_lbc_value(data['lbc_value'].toFixed(2))
            set_unr_value(data['unr_value'].toFixed(2))
            set_bce_value(data['bce_value'].toFixed(2))
            set_bcd_value(data['bcd_value'].toFixed(2))
            set_bad_value(data['bad_value'].toFixed(2))
            set_bod_value(data['bod_value'].toFixed(2))
            set_p_bra_value(data['p_bra_value'].toFixed(2))
            set_p_lbc_value(data['p_lbc_value'].toFixed(2))
            set_p_unr_value(data['p_unr_value'].toFixed(2))
            set_p_bce_value(data['p_bce_value'].toFixed(2))
            set_p_bcd_value(data['p_bcd_value'].toFixed(2))
            set_p_bad_value(data['p_bad_value'].toFixed(2))
            set_p_bod_value(data['p_bod_value'].toFixed(2))
            set_cx2l_value(data['cx2l_value'].toFixed(2))
            set_cx2a_value(data['cx2a_value'].toFixed(2))
            set_cx2b_value(data['cx2b_value'].toFixed(2))
            set_cx2lab_value(data['cx2lab_value'].toFixed(2))
            set_cemdl_value(data['cemdl_value'].toFixed(2))
            set_cemda_value(data['cemda_value'].toFixed(2))
            set_cemdb_value(data['cemdb_value'].toFixed(2))
            set_cemdlab_value(data['cemdlab_value'].toFixed(2))
            set_classification_value(data['aesthetic_classification'])
            set_scale_value(data['scale_value'].toFixed(2))
            setShowFeedback(false)
        }
        else {
            setShowFeedback(true)
        }
    }

    let handleSubjectiveClassification = async (value) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + p_image_id + '/classification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: value,
        })
        let data = await response
        if (response.status === 200) {
        }
    }

    let logInCankado = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("client_id", "ykg3gLgvYwq1ttGITgEDtXgzlUW1Qic79ZSB4Aen");
        urlencoded.append("client_secret", "xag3Vzn5afCpG51UreqYk3wshdoIfR9ZMr0taLgP7eTbZ53hFkcR6AwjmLKmVOZmUtwsgplJoEqhyQd8J8ILfQp7zLDnlMSoMSlzihI0hT6cVxxUPqToqzSLpphLdaWO");
        urlencoded.append("grant_type", "password");
        urlencoded.append("username", "inesc_user");
        urlencoded.append("password", "7gozj3LE39VLwPBK");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://api.cankado.com/can/token/", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    response.json().then(result => {
                        cankadoDetails = result
                        setCankadoAccessToken(result.access_token)
                    })
                }
                else if (response.status === 400) {
                    console.log("Invalid grant type")
                }
                else if (response.status === 401) {
                    console.log("Invalid client_id/client_secret/refresh_token")
                }
            })
    }


    let sendLogsCankado = async (action, status_code) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + cankadoDetails.access_token)

        let date = new Date

        var raw = JSON.stringify({
            "cid": institutional_number,
            "hcp_email": user.email,
            "inesc_action": action,
            "inesc_response_status_code": status_code,
            "datetime": [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('.') + " " + [date.getHours(), date.getMinutes()].join(":")
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        if (institutional_number != null) {
            fetch("https://api.cankado.com/cinderella/save/inesc-log/", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
        }

    }


    if (communicationError) {
        return (
            <Container>
                <Container className='content-container text-center'>
                    <h3>Error communicating with the database. Please try again later!</h3>
                </Container>
            </Container>
        )
    }
    else {
        return (
            <Container className='content-container mt-2'>

                <h3 className="my-4" style={{ textAlign: "justify" }}>Keypoint Manipulation Functions</h3>
                <div className='mb-3 text-center'>
                    {/*<Col>
                            <Button className='FilledButton' id="resetZoom">Return to Initial Zoom</Button>
                        </Col>
                        <Col>
                            <Button className='FilledButton' id="zoomIn">Zoom In</Button>
                        </Col>
                        <Col>
                            <Button className='FilledButton' id="zoomOut">Zoom Out</Button>
                        </Col>*/}

                    {/* TODO: Update this workflow */}
                    <Button className='FilledButton' onClick={refineKeypoints}>Refine Keypoints!</Button>
                    {/*<Col>
    
                            <Button className='FilledButton' onClick={() => getClassification(p_image_id)}>Save Features & Classification</Button>
                        </Col>*/}
                </div>

                <Row className='mb-4 text-center' style={{ height: "7vh", }}>
                    <Col>
                        <p className="px-2 mb-0">Countour and Markers Size</p>
                        <input className="slider" id="myRange3" name="sizeSlider" style={{ width: "30%", }} type="range" min="1" max="5" defaultValue='2' />
                    </Col>
                    <Col className='text-center'>
                        <div>
                            <Button className='FilledButton mt-2' id="refreshCanva">Refresh</Button>
                            <small style={{ display: "block", }}>Only use this button if the image doesn't load!</small>
                        </div>
                    </Col>
                </Row>

                {
                    browser_info != null ?
                        browser_info.browser.family === "Safari"?
                            <div>
                                <Button className='FilledButton mt-2' id="startCanva" onClick={() => {init(photo[0])}}>Start</Button>
                            </div>
                            :
                            null
                        :
                    null
                }        
                <Container id="overlay">
                    <LoadingOverlay active={isActive} spinner>
                        <canvas id="bcctCanvas" style={{ border: "1px solid #000000", maxWidth: '100%' }}></canvas>
                    </LoadingOverlay>
                </Container>

            </Container>
        )
    }
}


export default withRouter(BcctCoreCankadoPage)