import React from 'react';
import { createContext, useState, useEffect } from "react"
import jwt_decode from "jwt-decode"
import history from "../history";
import { withRouter } from "react-router-dom";


const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({ children }) => {

    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
    let [isAuthenticated, setIsAuthenticated] = useState(() => localStorage.getItem('authTokens') ? true : false)
    let [loading, setLoading] = useState(true)
    let [connected, setConnected] = useState(false)

    let url = null

    if (window.location.href.includes("localhost")){
        url = "localhost"
    }
    else{
        url = "194.117.27.135"
    }

    let loginUser = async (e) => {
        e.preventDefault();
        let response = await fetch('https://breloai-backend.inesctec.pt/api/token/', { 
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'username': e.target.username.value, 'password': e.target.password.value })
        })
        let data = await response.json()
        if (response.status === 200) {
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            setIsAuthenticated(true)
            localStorage.setItem('authTokens', JSON.stringify(data))
            setConnected(true)
            return true
        } else {
            return false
        }
    }

    let forceLogin = (data) => {
        logoutUser(0)
        setAuthTokens(data)
        setUser(jwt_decode(data.access))
        setIsAuthenticated(true)
        localStorage.setItem('authTokens', JSON.stringify(data))
        setConnected(true)

        return true
    }

    let logoutUser = async (num) => {
        setAuthTokens(null)
        setUser(null)
        setIsAuthenticated(false)
        await localStorage.removeItem('authTokens')
        if (num == 1){
            history.push('/login')
        }
    }

    let updateToken = async (token) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/token/refresh', { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'refresh': token })
        })
        let data = await response.json()
        if (response.status === 200) {
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
            setConnected(true)
            setIsAuthenticated(true)
        }
        else if (response.status === 401){
            logoutUser(1)
        } else {
            setConnected(false)
        }

        if (loading) {
            setLoading(false)
        }
    }


    let contextData = {
        loginUser: loginUser,
        logoutUser: logoutUser,
        updateToken: updateToken,
        user: user,
        authTokens: authTokens,
        isAuthenticated: isAuthenticated,
        connected: connected,
        forceLogin: forceLogin,
        url: url
    }

    useEffect(() => {

        /*if (loading) {
            updateToken(authTokens?.refresh)
        }*/

        let interval = setInterval(() => {
            if (authTokens) {
                updateToken(authTokens?.refresh)
            }
        }, 20000)

        return () => clearInterval(interval)

    }, [authTokens, loading])

    return (<AuthContext.Provider value={contextData}>
        {children}
    </AuthContext.Provider>)
}
