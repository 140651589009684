import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import history from "./history";
import { Router } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router history={history}>
    <App />
  </Router>
);
