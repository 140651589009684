import React, { Fragment, useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext'
import { withRouter, generatePath, useLocation } from "react-router-dom";
import history from '../history';
import axios from 'axios';
import { FileUploader } from "react-drag-drop-files";

import Container from 'react-bootstrap/esm/Container'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'



const fileTypes = ["JPG", "PNG", "jpeg", "png", "jpg", "tiff", "tif", "bmp"];

const UpdateUserPage = () => {
    let { authTokens, url } = useContext(AuthContext)

    const location = useLocation()

    const initialFormData = Object.freeze({
        username: location.state.username, email: location.state.email, password: '', password2: ''
    });

    const [formData, updateFormData] = useState(initialFormData);

    const [errors, setErrors] = useState({})

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
        setErrors({})
    };

    let updateInfo = async (e) => {
        e.preventDefault()
        if (image && image.name !== location.state.image.split("/").pop()) {
            let info = new FormData()
            info.append('image', image)
            if (image !== null && image.name !== location.state.image.split("/").pop()) {
                axios.put('https://breloai-backend.inesctec.pt/api/' + location.state.username + '/profile/editPicture', info, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + authTokens?.access,
                    }
                })
            }
        }

        let response = await fetch('https://breloai-backend.inesctec.pt/api/' + location.state.username + '/profile/edit', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: JSON.stringify(formData)
        })
        let data = await response.json()
        if (response.status === 200) {
            history.push({ pathname: generatePath("/:username/profile", { username: formData.username }), })
        }
        else if (response.status === 400 && data.username) {
            setErrors({ username: data.username })
        }
        else if (response.status === 400 && data.email) {
            setErrors({ email: data.email })
        }
        else if (response.status === 400 && data.password) {
            setErrors({ password: data.password })
        }

    }

    const [image, setImage] = useState(null)
    const handleImageChange = (file) => {
        setImage(file)
    };

    return (
        <Container className='content-container'>
            <h1>Edit Information</h1>
            <Form onSubmit={updateInfo}>
                <Form.Group className='my-3 mx-3'>
                    <Form.Label>New Username</Form.Label>
                    <Form.Control name="username" type="text" style={{ marginBottom: 0 }} isInvalid={!!errors.username} onChange={handleChange} value={formData.username} />
                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-3 mx-3'>
                    <Form.Label>New Email</Form.Label>
                    <Form.Control name="email" type="email" style={{ marginBottom: 0 }} isInvalid={!!errors.email} onChange={handleChange} value={formData.email} />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-3 mx-3'>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control name="password" type="password" style={{ marginBottom: 0 }} isInvalid={!!errors.password} onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-3 mx-3'>
                    <Form.Label>Repeat New Password</Form.Label>
                    <Form.Control name="password2" type="password" style={{ marginBottom: 0 }} isInvalid={!!errors.password} onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='my-3 mx-3'>
                    <Row>
                        <Col>
                            <Form.Label>New Profile Picture</Form.Label>
                            <FileUploader multiple={false} handleChange={handleImageChange} name="file" types={fileTypes} />
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Col>
                        <Col className='text-center'>
                            {image ?
                                <Image roundedCircle src={URL.createObjectURL(image)} width={200} height={200}></Image>
                                :
                                <Image roundedCircle src={'https://breloai-backend.inesctec.pt/media/' + location.state.image} width={200} height={200}></Image>
                            }
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className='text-center my-3'>
                    <Button className="addPatient-Submit-Button" variant="primary" type="submit">Update</Button>
                </Form.Group>
            </Form>
        </Container>
    )

}

export default withRouter(UpdateUserPage)