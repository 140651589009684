import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext'
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Container from 'react-bootstrap/esm/Container'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner';


const UserProfilePage = () => {
    let { authTokens, user, url } = useContext(AuthContext)

    const [appState, setAppState] = useState({
        isLoading: true,
        user: null,
        image: null,
    })

    let getInfo = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/' + user.username + '/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setAppState({
                isLoading: false,
                username: data.username,
                email: data.email,
                image: data.image,
            })
        }
    }

    useEffect(() => {
        getInfo()
    }, [])

    if (appState.isLoading === true) {
        return (
            <Container className='text-center'>
                <div style={{ marginTop: '22.5%', }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Container>
        )
    }
    else {
        return (
            <Container className='text-center my-4'>
                <h2 className='my-3'>{appState.username}'s Profile </h2>
                <Container className='text-center my-3'>
                    <Image roundedCircle width={200} height={200} src={'https://breloai-backend.inesctec.pt/media/' + appState.image}></Image>
                    <Container>
                        <h2>{appState.username}</h2>
                        <p>{appState.email}</p>
                    </Container>
                </Container>
                <div className='addPatient-div'>
                    <Button variant="outline-primary" className="addPatient-button my-auto" onClick={() => history.push({ pathname: generatePath("/:username/profile/edit", { username: appState.username }), state: {username: appState.username, image: appState.image, email: appState.email} })}><i className="fas fa-edit" aria-hidden="true"></i> Edit User Info</Button>
                </div>
            </Container>
        )
    }
}

export default withRouter(UserProfilePage)