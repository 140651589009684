import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthContext'
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';
import { useLocation } from "react-router-dom";

import Container from 'react-bootstrap/esm/Container'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Collapsible from 'react-collapsible';
import Select from 'react-select'

const EditPatientPage = () => {
  let { authTokens, url } = useContext(AuthContext)
  const [errors, setErrors] = useState({})

  const location = useLocation()

  let action = location.state.info.action

  const [birthday, setBirthday] = useState(action === "creation" || action === "photograph" ? location.state.info.date : null)
  const [height, setHeight] = useState(action === "creation" || action === "photograph" ? location.state.info.height : null)
  const [weight, setWeight] = useState(action === "creation" || action === "photograph" ? location.state.info.weight : null)
  const [bra_size, setBraSize] = useState(action === "creation" || action === "photograph" ? location.state.info.bra_size : null)
  const [bra_cup, setBraCup] = useState(action === "creation" || action === "photograph" ? location.state.info.bra_cup : null)

  const [surgery_date, setSurgeryDate] = useState(action === "surgery" ? location.state.info.date : null)
  const [surgical_indication, setSurgicalIndication] = useState(action === "surgery" ? location.state.info.surgical_indication.map(Number) : null)
  const [surgery_type, setSurgeryType] = useState(action === "surgery" ? location.state.info.surgery_type.map(Number) : null)
  const [sides, setSides] = useState(action === "surgery" ? location.state.info.cancer_sides : null)

  const [start_date, setStartDate] = useState(action === "radiotherapy" ? location.state.info.date : null)
  const [end_date, setEndDate] = useState(action === "radiotherapy" ? location.state.info.end_date : null)
  const [total_dose, setTotalDose] = useState(action === "radiotherapy" ? location.state.info.total_dose : null)
  const [boost_dose, setBoostDose] = useState(action === "radiotherapy" ? location.state.info.boost_dose : null)

  const [acquisition_date, setAcquisitionDate] = useState(action === "photograph" ? location.state.info.date : null)

  const options1 = [
    { value: "1", label: 'C1 - Conservative Surgery' },
    { value: "2", label: 'C2 - Conservative Surgery with bilateral reduction' },
    { value: "3", label: 'C3 - Conservative Surgery with LD or LICAC/TDAP flaps' },
    { value: "4", label: 'C4 - Conservative Surgery with bilateral implants' },
    { value: "5", label: 'M1 - Mastectomy with unilateral reconstruction with implant' },
    { value: "6", label: 'M2 - Mastectomy with unilateral reconstruction with autologous flap' },
    { value: "7", label: 'M3 - Mastectomy with bilateral reconstruction with implants' },
    { value: "8", label: 'M4 - Mastectomy with bilateral reconstruction with autologous flaps' },
    { value: "9", label: 'M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)' },
    { value: "10", label: 'M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction' },
    { value: "11", label: 'M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction' },
    { value: "12", label: 'M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)' },
  ]

  const options2 = [
    { value: "5", label: 'M1 - Mastectomy with unilateral reconstruction with implant' },
    { value: "6", label: 'M2 - Mastectomy with unilateral reconstruction with autologous flap' },
    { value: "7", label: 'M3 - Mastectomy with bilateral reconstruction with implants' },
    { value: "8", label: 'M4 - Mastectomy with bilateral reconstruction with autologous flaps' },
    { value: "9", label: 'M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)' },
    { value: "10", label: 'M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction' },
    { value: "11", label: 'M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction' },
    { value: "12", label: 'M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)' },
  ]

  const options3 = [
    { value: "13", label: 'P1 - Fat Grafting' },
    { value: "14", label: 'P2 - Scar Revision' },
    { value: "15", label: 'P3 - Nipple Reconstruction' },
    { value: "16", label: 'P4a - Unilateral Delayed Reconstruction with autolugous flap' },
    { value: "17", label: 'P4b - Bilateral Delayed Reconstruction with autolugous flap' },
    { value: "18", label: 'P5a - Unilateral Delayed Reconstruction with implant' },
    { value: "19", label: 'P5b - Bilateral Delayed Reconstruction with implant' },
    { value: "20", label: 'P6a - Unilateral Capsulectomy and implant replacement' },
    { value: "21", label: 'P6b - Bilateral Capsulectomy and implant replacement' },
    { value: "22", label: 'P7a - Uniloateral Capsulectomy and implant removal' },
    { value: "23", label: 'P7b - Biloateral Capsulectomy and implant removal' },
    { value: "24", label: 'P8a - Unilateral Reduction' },
    { value: "28", label: 'P8b - Bilateral Reduction' }
  ]

  const options4 = [
    { value: '1', label: "Cancer" },
    { value: "2", label: "Prophylatic" },
    { value: "3", label: "Improvement" },
    { value: "4", label: "Benign" }
  ]

  const options5 = [
    { value: "1", label: 'Left' },
    { value: "2", label: 'Right' },
  ]

  let editPatient = async (e) => {
    if (action === "creation") {
      let response = await fetch('https://breloai-backend.inesctec.pt/api/editBaseInfo/' + location.state.info.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authTokens?.access
        },
        body: JSON.stringify({
          'birthday': birthday,
          'height': height,
          'weight': weight,
          'bra_size': bra_size,
          'bra_cup': bra_cup,
          'type': 2
        })
      })
      let data = await response
      if (response.status == 200) {
        history.push({ pathname: generatePath("/patient/:id", { id: location.state.patient_id }), state: { id: location.state.patient_id } })
      }
      else if (response.status == 400 && data.birthday) {
        setErrors({ birthday: data.birthday })
      }
    }
    else if (action === "surgery") {
      let response = await fetch('https://breloai-backend.inesctec.pt/api/editSurgeryInfo/' + location.state.info.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authTokens?.access
        },
        body: JSON.stringify({
          'surgery_date': surgery_date,
          'surgical_indication': surgical_indication,
          'surgery_type': surgery_type,
          'sides': sides
        })
      })
      let data = await response
      if (response.status == 200) {
        history.push({ pathname: generatePath("/patient/:id", { id: location.state.patient_id }), state: { id: location.state.patient_id } })
      }
    }
    else if (action === "radiotherapy") {
      let response = await fetch('https://breloai-backend.inesctec.pt/api/editRadiotherapyInfo/' + location.state.info.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authTokens?.access
        },
        body: JSON.stringify({
          'start_date': start_date,
          'end_date': end_date,
          'total_dose': total_dose,
          'boost_dose': boost_dose
        })
      })
      let data = await response
      if (response.status == 200) {
        history.push({ pathname: generatePath("/patient/:id", { id: location.state.patient_id }), state: { id: location.state.patient_id } })
      }
    }
    else if (action === "photograph") {
      let response = await fetch('https://breloai-backend.inesctec.pt/api/editPhotographMomentInfo/' + location.state.info.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authTokens?.access
        },
        body: JSON.stringify({
          'acquisition_date': acquisition_date,
          'height': height,
          'weight': weight,
          'bra_size': bra_size,
          'bra_cup': bra_cup
        })
      })
      let data = await response
      if (response.status === 200) {
        history.push({ pathname: generatePath("/patient/:id", { id: location.state.patient_id }), state: { id: location.state.patient_id } })
      }
    }

  }

  let getDefaultValueSurgicalIndiation = () => {
    let result = []
    options4.map((option) => {
      if (surgical_indication.includes(parseInt(option.value))) {

        result.push(option)
      }
    })
    return result
  }

  let getDefaultValueSurgeryType = () => {

    let result = []
    options1.map((option) => {
      if (surgery_type.includes(parseInt(option.value))) {
        result.push(option)
      }
    })
    options3.map((option) => {
      if (surgery_type.includes(parseInt(option.value))) {
        result.push(option)
      }
    })
    return result
  }

  let getDefaultValueSides = () => {
    if (sides === 1) {
      return [
        { value: "1", label: 'Left' }
      ]
    }
    else if (sides === 2) {
      return [
        { value: "2", label: 'Right' },
      ]
    }
    else if (sides === 3) {
      return [
        { value: "1", label: 'Left' },
        { value: "2", label: 'Right' },
      ]
    }

  }

  return (
    <Container style={{ minWidth: "80vw" }}>

      <Breadcrumb className='mt-2'>
        <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
        <Breadcrumb.Item active>
          Edit Patient
        </Breadcrumb.Item>
      </Breadcrumb>

      <Container className='my-auto'>

        <h2 className='text-center my-3'><strong>Edit Patient</strong></h2>

        {
          action === "creation" ?
            <Form.Group className="mx-0">
              <Col>
                <Form.Group>
                  <Form.Label>Birthday</Form.Label>
                  <Form.Control name="birthday" type="date" defaultValue={birthday} onChange={(e) => setBirthday(e.target.value)} isInvalid={!!errors.birthday} />
                  <Form.Control.Feedback type="invalid">{errors.birthday}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Row className='my-3'>
                <Col>
                  <Form.Group>
                    <Form.Label>Height</Form.Label>
                    <Form.Control name="height" type="text" defaultValue={height} onChange={(e) => setHeight(e.target.value)} />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <Form.Label>Weight</Form.Label>
                    <Form.Control name="weight" type="text" defaultValue={weight} onChange={(e) => setWeight(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='my-3'>
                <Col>
                  <Form.Group>
                    <Form.Label>Bra Size</Form.Label>
                    <Form.Control name="bra_size" type="text" defaultValue={bra_size} onChange={(e) => setBraSize(e.target.value)} />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <Form.Label>Bra Cup</Form.Label>
                    <Form.Control name="bra_cup" type="text" defaultValue={bra_cup} onChange={(e) => setBraCup(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
            </Form.Group>

            :

            action === "radiotherapy" ?

              <Form.Group className="mx-0">
                <Row className='my-3'>
                  <Col>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control name="start_Date" type="date" defaultValue={start_date} onChange={(e) => setStartDate(e.target.value)} />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>
                      <Form.Control name="end_Date" type="date" defaultValue={end_date} onChange={(e) => setEndDate(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='my-3'>
                  <Col>
                    <Form.Group>
                      <Form.Label>Total Dose</Form.Label>
                      <Form.Control name="total_dose" type="text" defaultValue={total_dose} onChange={(e) => setTotalDose(e.target.value)} />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <Form.Label>Boost Dose</Form.Label>
                      <Form.Control name="boost_dose" type="text" defaultValue={boost_dose} onChange={(e) => setBoostDose(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>

              :

              action === "surgery" ?
                <Form.Group className="mx-0">
                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Surgery Date</Form.Label>
                      <Form.Control name={"surgery_date"} type="date" defaultValue={surgery_date} onChange={(e) => setSurgeryDate(e.target.value)} />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Surgical Indication</Form.Label>
                      <Select isMulti options={options4} defaultValue={getDefaultValueSurgicalIndiation} className="basic-multi-select w-100" classNamePrefix="select" name={"surgical_indication"}
                        onChange={(e) => {
                          surgical_indication.length = 0
                          let aux2 = []
                          e.map((aux) => { aux2.push(parseInt(aux.value)) })
                          setSurgicalIndication(aux2)
                        }
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Surgery Type</Form.Label>
                      {
                        surgical_indication.length != 0 ?
                          <Select
                            isMulti
                            options={surgical_indication.includes(1) && surgical_indication.includes(2) && surgical_indication.includes(3) ? options1.concat(options3) :
                              surgical_indication.includes(1) && surgical_indication.includes(2) && !surgical_indication.includes(3) ? options1 :
                                surgical_indication.includes(1) && !surgical_indication.includes(2) && surgical_indication.includes(3) ? options1.concat(options3) :
                                  !surgical_indication.includes(1) && surgical_indication.includes(2) && surgical_indication.includes(3) ? options2.concat(options3) :
                                    surgical_indication.includes(1) && !surgical_indication.includes(2) && !surgical_indication.includes(3) ? options1 :
                                      !surgical_indication.includes(1) && surgical_indication.includes(2) && !surgical_indication.includes(3) ? options2 :
                                        !surgical_indication.includes(1) && !surgical_indication.includes(2) && surgical_indication.includes(3) ? options3 :
                                          null
                            }
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            name={"surgery_type"}
                            defaultValue={getDefaultValueSurgeryType}
                            onChange={(e) => {
                              let aux2 = []
                              e.map((aux) => { aux2.push(parseInt(aux.value)) })
                              setSurgeryType(aux2)
                            }
                            }
                          />
                          :
                          <Select isDisabled={true} />
                      }
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className='mb-3'>
                      <Form.Label>Cancer Side</Form.Label>
                      <Select isMulti options={options5} defaultValue={getDefaultValueSides} className="basic-multi-select w-100" classNamePrefix="select" name={"cancer_sides"}
                        onChange={(e) => {
                          if (e.length === 1) {
                            setSides(parseInt(e[0].value))
                          }
                          else if (e.length === 2) {
                            setSides(3)
                          }
                          else if (e.length === 0) {
                            setSides(null)
                          }
                        }} />
                    </Form.Group>
                  </Col>
                </Form.Group>
                :

                action === "photograph" ?

                  <Form.Group className="mx-0">
                    <Col>
                      <Form.Group>
                        <Form.Label>Acquisition Date</Form.Label>
                        <Form.Control name="birthday" type="date" defaultValue={acquisition_date} onChange={(e) => setAcquisitionDate(e.target.value)}/>
                      </Form.Group>
                    </Col>

                    <Row className='my-3'>
                      <Col>
                        <Form.Group>
                          <Form.Label>Height</Form.Label>
                          <Form.Control name="height" type="text" defaultValue={height} onChange={(e) => setHeight(e.target.value)} />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group>
                          <Form.Label>Weight</Form.Label>
                          <Form.Control name="weight" type="text" defaultValue={weight} onChange={(e) => setWeight(e.target.value)} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className='my-3'>
                      <Col>
                        <Form.Group>
                          <Form.Label>Bra Size</Form.Label>
                          <Form.Control name="bra_size" type="text" defaultValue={bra_size} onChange={(e) => setBraSize(e.target.value)} />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group>
                          <Form.Label>Bra Cup</Form.Label>
                          <Form.Control name="bra_cup" type="text" defaultValue={bra_cup} onChange={(e) => setBraCup(e.target.value)} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group>

                  :

                  null
        }

        <Form.Group className='text-center'>
          <Button className="addPatient-Submit-Button" variant="primary" onClick={() => { editPatient() }}>Save</Button>
        </Form.Group>

      </Container>
    </Container>
  )
}

export default withRouter(EditPatientPage)
