import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container';
import AuthContext from '../context/AuthContext';
import history from '../history';
import { withRouter, generatePath, useLocation } from "react-router-dom";
import axios from 'axios'

import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/esm/Image';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import LoadingOverlay from 'react-loading-overlay';
import Collapsible from 'react-collapsible';

import * as createjs from 'createjs-module';


const NippleAnnotationPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)
    var smoothArrayJs = require("smooth-array-js")

    const location = useLocation()

    var patient_id = location.state != undefined ? location.state.patient_id : location.pathname.split('/')[2]
    var p_image_id = location.state != undefined ? location.state.photo.id : location.pathname.split('/')[4]
    var photo_date = location.pathname.split('/')[3]
    let page_info = location.state != undefined ? location.state.page : null
    var institutional_number = location.state != undefined ? location.state.institutional_number : null
    let surgery_date = location.state != undefined ? location.state.surgery_date : null

    const [isActive, setIsActive] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false);

    var canvas, stage;
    //var update = true;
    const [update, setUpdate] = useState(true)
    var nMarkers = 16;

    // Indices of the Keypoints in the UI
    var leftContourIdxStart = 0;
    var leftContourIdxEnd = 7;
    var rightContourIdxStart = 8;
    var rightContourIdxEnd = 15;

    //
    const [markersVec, set_markersVec] = useState([])
    var contours;
    var bitmap_bck;
    var loaded_img;
    var container;
    var zoomStatus = 0;
    const [photo, setPhoto] = useState(null)

    

    const start = async () => {
        await axios.get('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + photo_date + '/' + p_image_id + '/bcctcore', {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        }).then(res => {
            setPhoto(res.data.data)
            return res.data.data
        }).then((res) => {
            init(res)
        })
    }

    const init = (photo) => {
        canvas = document.getElementById("bcctCanvas")

        stage = new createjs.Stage(canvas);
        // enable touch interactions if supported on the current device:
        createjs.Touch.enable(stage);
        // enabled mouse over / out events
        stage.enableMouseOver(10);
        stage.mouseMoveOutside = true; // keep tracking the mouse even when it leaves the canvas
        loaded_img = false;
        container = new createjs.Container();

        stage.addChild(container);

        container.removeAllChildren();

        bitmap_bck = new createjs.Bitmap("https://breloai-backend.inesctec.pt" + photo.image)
        container.addChild(bitmap_bck);
        bitmap_bck.originalScale = 1.0;
        bitmap_bck.scale = 1.0;

        canvas.height = bitmap_bck.image.height * bitmap_bck.originalScale;
        canvas.width = bitmap_bck.image.width * bitmap_bck.originalScale;

        // create and populate the screen with markers:
        for (var i = 0; i < nMarkers; i++) {
            markersVec.push(new createjs.Container());
            var shapeMarker = new createjs.Shape();
            var g = shapeMarker.graphics;
            
            g.beginFill("#DFDFDF");
            shapeMarker.alpha = 0.5;
            
            var radius = canvas.height / 80;
            g.drawCircle(0, 0, radius);
            g.endFill();

            markersVec[i].addChild(shapeMarker);
            shapeMarker = new createjs.Shape();
            g = shapeMarker.graphics;
            g.beginFill("#000000");
            g.drawCircle(0, 0, 1);
            g.endFill();
            markersVec[i].addChild(shapeMarker);

            container.addChild(markersVec[i]);
            markersVec[i].scale = markersVec[i].originalScale = 1;//Math.random() * 0.4 + 0.6;
            markersVec[i].name = "bmp_" + i;
            markersVec[i].cursor = "pointer";

            // using "on" binds the listener to the scope of the currentTarget by default
            // in this case that means it executes in the scope of the button.
            markersVec[i].on("mousedown", function (evt) {
                this.parent.addChild(this);
                this.offset = { x: this.x - evt.stageX, y: this.y - evt.stageY };
            });
            // the pressmove event is dispatched when the mouse moves after a mousedown on the target until the mouse is released.
            markersVec[i].on("pressmove", function (evt) {
                this.x = evt.stageX + this.offset.x;
                this.y = evt.stageY + this.offset.y;
                // indicate that the stage should be updated on the next tick:
                //update = true;
                setUpdate(true)
            });
            markersVec[i].on("rollover", function (evt) {
                this.scale = this.originalScale * 1.2;
                //update = true;
                setUpdate(true)
            });
            markersVec[i].on("rollout", function (evt) {
                this.scale = this.originalScale;
                //update = true;
                setUpdate(true)
                savePointsDB()
            });
        }

        // Scale Mark
        markersVec[leftContourIdxStart].x = photo.nipple_annotation_status === false ? 0.7 * canvas.width : photo.l_nipple_contour_1_x;
        markersVec[leftContourIdxStart].y = photo.nipple_annotation_status === false ? 0.6 * canvas.height : photo.l_nipple_contour_1_y;

        markersVec[leftContourIdxStart + 1].x = photo.nipple_annotation_status === false ? 0.71 * canvas.width : photo.l_nipple_contour_2_x;
        markersVec[leftContourIdxStart + 1].y = photo.nipple_annotation_status === false ? 0.605 * canvas.height : photo.l_nipple_contour_2_y;

        markersVec[leftContourIdxStart + 2].x = photo.nipple_annotation_status === false ? 0.715 * canvas.width : photo.l_nipple_contour_3_x;
        markersVec[leftContourIdxStart + 2].y = photo.nipple_annotation_status === false ? 0.615 * canvas.height : photo.l_nipple_contour_3_y;

        markersVec[leftContourIdxStart + 3].x = photo.nipple_annotation_status === false ? 0.71 * canvas.width : photo.l_nipple_contour_4_x;
        markersVec[leftContourIdxStart + 3].y = photo.nipple_annotation_status === false ? 0.625 * canvas.height : photo.l_nipple_contour_4_y;

        markersVec[leftContourIdxStart + 4].x = photo.nipple_annotation_status === false ? 0.7 * canvas.width : photo.l_nipple_contour_5_x;
        markersVec[leftContourIdxStart + 4].y = photo.nipple_annotation_status === false ? 0.63 * canvas.height : photo.l_nipple_contour_5_y;

        markersVec[leftContourIdxStart + 5].x = photo.nipple_annotation_status === false ? 0.69 * canvas.width : photo.l_nipple_contour_6_x;
        markersVec[leftContourIdxStart + 5].y = photo.nipple_annotation_status === false ? 0.625 * canvas.height : photo.l_nipple_contour_6_y;

        markersVec[leftContourIdxStart + 6].x = photo.nipple_annotation_status === false ? 0.685 * canvas.width : photo.l_nipple_contour_7_x;
        markersVec[leftContourIdxStart + 6].y = photo.nipple_annotation_status === false ? 0.615 * canvas.height : photo.l_nipple_contour_7_y;

        markersVec[leftContourIdxStart + 7].x = photo.nipple_annotation_status === false ? 0.69 * canvas.width : photo.l_nipple_contour_8_x;
        markersVec[leftContourIdxStart + 7].y = photo.nipple_annotation_status === false ? 0.605 * canvas.height : photo.l_nipple_contour_8_y;

        markersVec[rightContourIdxStart].x = photo.nipple_annotation_status === false ? 0.3 * canvas.width : photo.r_nipple_contour_1_x;
        markersVec[rightContourIdxStart].y = photo.nipple_annotation_status === false ? 0.6 * canvas.height : photo.r_nipple_contour_1_y;

        markersVec[rightContourIdxStart + 1].x = photo.nipple_annotation_status === false ? 0.31 * canvas.width : photo.r_nipple_contour_2_x;
        markersVec[rightContourIdxStart + 1].y = photo.nipple_annotation_status === false ? 0.605 * canvas.height : photo.r_nipple_contour_2_y;

        markersVec[rightContourIdxStart + 2].x = photo.nipple_annotation_status === false ? 0.315 * canvas.width : photo.r_nipple_contour_3_x;
        markersVec[rightContourIdxStart + 2].y = photo.nipple_annotation_status === false ? 0.615 * canvas.height : photo.r_nipple_contour_3_y;

        markersVec[rightContourIdxStart + 3].x = photo.nipple_annotation_status === false ? 0.31 * canvas.width : photo.r_nipple_contour_4_x;
        markersVec[rightContourIdxStart + 3].y = photo.nipple_annotation_status === false ? 0.625 * canvas.height : photo.r_nipple_contour_4_y;

        markersVec[rightContourIdxStart + 4].x = photo.nipple_annotation_status === false ? 0.3 * canvas.width : photo.r_nipple_contour_5_x;
        markersVec[rightContourIdxStart + 4].y = photo.nipple_annotation_status === false ? 0.63 * canvas.height : photo.r_nipple_contour_5_y;

        markersVec[rightContourIdxStart + 5].x = photo.nipple_annotation_status === false ? 0.29 * canvas.width : photo.r_nipple_contour_6_x;
        markersVec[rightContourIdxStart + 5].y = photo.nipple_annotation_status === false ? 0.625 * canvas.height : photo.r_nipple_contour_6_y;

        markersVec[rightContourIdxStart + 6].x = photo.nipple_annotation_status === false ? 0.285 * canvas.width : photo.r_nipple_contour_7_x;
        markersVec[rightContourIdxStart + 6].y = photo.nipple_annotation_status === false ? 0.615 * canvas.height : photo.r_nipple_contour_7_y;

        markersVec[rightContourIdxStart + 7].x = photo.nipple_annotation_status === false ? 0.29 * canvas.width : photo.r_nipple_contour_8_x;
        markersVec[rightContourIdxStart + 7].y = photo.nipple_annotation_status === false ? 0.605 * canvas.height : photo.r_nipple_contour_8_y;

        contours = new createjs.Shape();
        container.addChild(contours);

        loaded_img = true;
        //update = true;
        setUpdate(true)

        createjs.Ticker.addEventListener("tick", tick);

        document.getElementById("myRange3").addEventListener("mouseup", (e) => updateMarkerSizes())
    }

    function updateMarkerSizes() {
        markersVec.forEach(marker => {
            marker.children[0].scaleX = document.getElementById("myRange3").value / 2.5
            marker.children[0].scaleY = document.getElementById("myRange3").value / 2.5
            marker.children[1].scaleX = document.getElementById("myRange3").value
            marker.children[1].scaleY = document.getElementById("myRange3").value
        })
        setUpdate(true)
    }

    function tick(event) {
        // this set makes it so the stage only re-renders when an event handler indicates a change has happened.
        if (update) {
            //update = false; // only update once
            setUpdate(false)
            drawContour();
            stage.update(event);
        }
    }

    function drawContour() {
        var spline_res = 64;
        contours.graphics.clear();
        contours.graphics.setStrokeStyle(document.getElementById("myRange3").value * 2).beginStroke("rgba(0,0,0,1)");

        var points = [];
        for (var i = leftContourIdxStart; i <= leftContourIdxEnd; i++) {
            var aux_ = [];
            aux_.push(markersVec[i].x);
            aux_.push(markersVec[i].y);
            points.push(aux_);
        }
        var path = smoothArrayJs.Smooth(points, {
            method: smoothArrayJs.Smooth.METHOD_CUBIC,
            cubicTension: smoothArrayJs.Smooth.CUBIC_TENSION_CATMULL_ROM
        });

        var aux2 = path(0);
        contours.graphics.moveTo(aux2[0], aux2[1]);
        for (var i = 0; i <= points.length - 1; i += (1.0 / spline_res)) {
            var aux3 = path(i);
            contours.graphics.lineTo(aux3[0], aux3[1]);
        }
        contours.graphics.lineTo(aux2[0], aux2[1]);

        points = [];
        for (var i = rightContourIdxStart; i <= rightContourIdxEnd; i++) {
            var aux = [];
            aux.push(markersVec[i].x);
            aux.push(markersVec[i].y);
            points.push(aux);
        }
        path = smoothArrayJs.Smooth(points, {
            method: smoothArrayJs.Smooth.METHOD_CUBIC,
            cubicTension: smoothArrayJs.Smooth.CUBIC_TENSION_CATMULL_ROM
        });

        var aux = path(0);
        contours.graphics.moveTo(aux[0], aux[1]);
        for (var i = 0; i <= points.length - 1; i += (1.0 / spline_res)) {
            var aux1 = path(i);
            contours.graphics.lineTo(aux1[0], aux1[1]);
        }
        contours.graphics.lineTo(aux[0], aux[1]);


        contours.graphics.endStroke();
    }


    /*var zoom = (factor) => {
        if (!loaded_img)
            return;
        // if (zoomStatus == 0 && factor < 1.0) {
        // return;
        // }
        bitmap_bck.scale *= factor;
        bitmap_bck.scaleX *= factor;
        bitmap_bck.scaleY *= factor;
        canvas.height = bitmap_bck.image.height * bitmap_bck.scale;
        canvas.width = bitmap_bck.image.width * bitmap_bck.scale;
        //change pos of markers
        for (var i = 0; i < nMarkers; i++) {
            markersVec[i].x *= factor;
            markersVec[i].y *= factor;
        }
        //update = true;
        setUpdate(true)
        if (factor < 1.0) {
            zoomStatus -= 1;
        } else if (factor > 1.0) {
            zoomStatus += 1;
        } else {
            zoomStatus += 0;
        }
    }

    var returnToInitialZoomState = () => {
        var factor = null
        if (zoomStatus < 1.0) {
            factor = Math.pow(2.0, Math.abs(zoomStatus));
        } else {
            factor = Math.pow(0.5, Math.abs(zoomStatus));
        }

        bitmap_bck.scale *= factor;
        bitmap_bck.scaleX *= factor;
        bitmap_bck.scaleY *= factor;
        canvas.height = bitmap_bck.image.height * bitmap_bck.scale;
        canvas.width = bitmap_bck.image.width * bitmap_bck.scale;

        for (var i = 0; i < nMarkers; i++) {
            markersVec[i].x *= factor;
            markersVec[i].y *= factor;
        }
        //update = true;
        setUpdate(true)
        zoomStatus = 0;
    }

    // Function: Print several values to the JavaScript console (TODO: Erase this uppon review)
    function logmarkers() {
        console.log(markersVec[sternalNotchIdx].x, markersVec[sternalNotchIdx].y);
        console.log(markersVec[leftNippleIdx].x, markersVec[leftNippleIdx].y);
        console.log(markersVec[rightContourIdxStart].x, markersVec[rightContourIdxStart].y);
        console.log(markersVec[rightContourIdxEnd].x, markersVec[rightContourIdxEnd].y);
        console.log(markersVec[leftContourIdxEnd].x, markersVec[leftContourIdxEnd].y);
        console.log(featurePanel.x, featurePanel.y);
        console.log(zoomStatus);
    }*/

    function stop() {
        createjs.Ticker.removeEventListener("tick", tick);
    }

    useEffect(() => {
        start()
    }, [])

    async function savePointsDB() {
        var factor = null
        if (zoomStatus < 1.0) {
            factor = Math.pow(2.0, Math.abs(zoomStatus));
        } else {
            factor = Math.pow(0.5, Math.abs(zoomStatus));
        }
        // let keypoints = Object.assign({}, markersVec);

        let keypoints = {}; // objCopy will store a copy of the mainObj
        let key;

        for (key in markersVec) {
            keypoints[key] = markersVec[key]; // copies each property to the objCopy object
        }

        for (var i = 0; i < nMarkers; i++) {
            keypoints[i].x *= factor;
            keypoints[i].y *= factor;
        }

        // Right Breast Contour
        let r_nipple_contour_1_x = keypoints[8].x
        let r_nipple_contour_1_y = keypoints[8].y

        let r_nipple_contour_2_x = keypoints[9].x
        let r_nipple_contour_2_y = keypoints[9].y

        let r_nipple_contour_3_x = keypoints[10].x
        let r_nipple_contour_3_y = keypoints[10].y

        let r_nipple_contour_4_x = keypoints[11].x
        let r_nipple_contour_4_y = keypoints[11].y

        let r_nipple_contour_5_x = keypoints[12].x
        let r_nipple_contour_5_y = keypoints[12].y

        let r_nipple_contour_6_x = keypoints[13].x
        let r_nipple_contour_6_y = keypoints[13].y

        let r_nipple_contour_7_x = keypoints[14].x
        let r_nipple_contour_7_y = keypoints[14].y

        let r_nipple_contour_8_x = keypoints[15].x
        let r_nipple_contour_8_y = keypoints[15].y
        
        // Left Breast Contour
        let l_nipple_contour_1_x = keypoints[0].x
        let l_nipple_contour_1_y = keypoints[0].y

        let l_nipple_contour_2_x = keypoints[1].x
        let l_nipple_contour_2_y = keypoints[1].y

        let l_nipple_contour_3_x = keypoints[2].x
        let l_nipple_contour_3_y = keypoints[2].y

        let l_nipple_contour_4_x = keypoints[3].x
        let l_nipple_contour_4_y = keypoints[3].y

        let l_nipple_contour_5_x = keypoints[4].x
        let l_nipple_contour_5_y = keypoints[4].y

        let l_nipple_contour_6_x = keypoints[5].x
        let l_nipple_contour_6_y = keypoints[5].y

        let l_nipple_contour_7_x = keypoints[6].x
        let l_nipple_contour_7_y = keypoints[6].y

        let l_nipple_contour_8_x = keypoints[7].x
        let l_nipple_contour_8_y = keypoints[7].y



        // Create a dictionary with this data
        var keypoint_data = {
            'patient_id': patient_id,
            'p_image_id': p_image_id,
            'l_nipple_contour_1_x': l_nipple_contour_1_x,
            'l_nipple_contour_1_y': l_nipple_contour_1_y,
            'l_nipple_contour_2_x': l_nipple_contour_2_x,
            'l_nipple_contour_2_y': l_nipple_contour_2_y,
            'l_nipple_contour_3_x': l_nipple_contour_3_x,
            'l_nipple_contour_3_y': l_nipple_contour_3_y,
            'l_nipple_contour_4_x': l_nipple_contour_4_x,
            'l_nipple_contour_4_y': l_nipple_contour_4_y,
            'l_nipple_contour_5_x': l_nipple_contour_5_x,
            'l_nipple_contour_5_y': l_nipple_contour_5_y,
            'l_nipple_contour_6_x': l_nipple_contour_6_x,
            'l_nipple_contour_6_y': l_nipple_contour_6_y,
            'l_nipple_contour_7_x': l_nipple_contour_7_x,
            'l_nipple_contour_7_y': l_nipple_contour_7_y,
            'l_nipple_contour_8_x': l_nipple_contour_8_x,
            'l_nipple_contour_8_y': l_nipple_contour_8_y,
            'r_nipple_contour_1_x': r_nipple_contour_1_x,
            'r_nipple_contour_1_y': r_nipple_contour_1_y,
            'r_nipple_contour_2_x': r_nipple_contour_2_x,
            'r_nipple_contour_2_y': r_nipple_contour_2_y,
            'r_nipple_contour_3_x': r_nipple_contour_3_x,
            'r_nipple_contour_3_y': r_nipple_contour_3_y,
            'r_nipple_contour_4_x': r_nipple_contour_4_x,
            'r_nipple_contour_4_y': r_nipple_contour_4_y,
            'r_nipple_contour_5_x': r_nipple_contour_5_x,
            'r_nipple_contour_5_y': r_nipple_contour_5_y,
            'r_nipple_contour_6_x': r_nipple_contour_6_x,
            'r_nipple_contour_6_y': r_nipple_contour_6_y,
            'r_nipple_contour_7_x': r_nipple_contour_7_x,
            'r_nipple_contour_7_y': r_nipple_contour_7_y,
            'r_nipple_contour_8_x': r_nipple_contour_8_x,
            'r_nipple_contour_8_y': r_nipple_contour_8_y,  
        };

        for (var i = 0; i < nMarkers; i++) {
            keypoints[i].x /= factor;
            keypoints[i].y /= factor;
        }


        let accessToken = localStorage.getItem('authTokens')
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/' + p_image_id + '/updateNippleKeyPoints', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: JSON.stringify(keypoint_data),
        })
    }

    return (
        <Container>
            {
                institutional_number != null ?
                    <Breadcrumb>
                        {page_info === null ? <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item> : page_info.source === "home" ? <Breadcrumb.Item onClick={() => { history.push({ pathname: generatePath("/:username/patients", { username: user.username }), state: { page_info: page_info } }) }}>Patients</Breadcrumb.Item> : page_info.source === "dashboard" ? <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/:username/dashboard", { username: user.username }), state: { page_info: page_info } })}>Dashboard</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>}
                        <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/patient/:id", { id: patient_id }), state: { id: patient_id } })}>
                            Patient {institutional_number} Profile
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/patient/:id/surgery/:surgery_date/photos/:date", { id: patient_id, surgery_date: surgery_date, date: photo['date_created'],  }), state: { patient_id: patient_id, date: photo['date_created'], page: page_info, institutional_number: institutional_number, surgery_date: surgery_date } })}>
                            Patient {institutional_number} Photos
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Patient {institutional_number} Nipple Annotation
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    :
                    null
            }

            <Container className='content-container'>

                <div className='mb-4 text-center'>
                    <p className="px-2 mb-0">Countour and Markers Size</p>
                    <input className="slider" id="myRange3" name="sizeSlider" style={{ width: "30%", }} type="range" min="1" max="5" defaultValue='2' />
                </div>

                <Container>
                    <LoadingOverlay active={isActive} spinner text='Refining keypoints...'>
                        <canvas id="bcctCanvas" style={{ border: "1px solid #000000", maxWidth: '100%' }}></canvas>
                    </LoadingOverlay>
                </Container>

            </Container>

        </Container>

    )

}


export default withRouter(NippleAnnotationPage)