import React, { useContext, useEffect, useState } from 'react'
import { withRouter, useLocation, generatePath } from "react-router-dom"
import AuthContext from '../context/AuthContext';
import history from '../history';

import axios from 'axios';

import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/esm/Col";
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { FileUploader } from "react-drag-drop-files";
import Compressor from 'compressorjs';

import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select'

const CreateSurgeryPage = () => {
    let { authTokens, user, url } = useContext(AuthContext)
    const location = useLocation()

    let institutional_number = location.state.patient !== undefined ? location.state.patient.institutional_number : location.state.institutional_number
    let id = location.state.patient !== undefined ? location.state.patient.id : location.state.id
    let page_info = location.state.page !== undefined ? location.state.page : null
    let surgery_date = location.state.surgery_date !== undefined ? location.state.surgery_date : null

    const [errors, setErrors] = useState({})

    const [surgicalIndication, setSurgicalIndication] = useState([])
    const [surgeryType, setSurgeryType] = useState([])
    const [cancerSide, setCancerSide] = useState([])

    const options1 = [
        { value: "1", label: 'C1 - Conservative Surgery' },
        { value: "2", label: 'C2 - Conservative Surgery with bilateral reduction' },
        { value: "3", label: 'C3 - Conservative Surgery with LD or LICAC/TDAP flaps' },
        { value: "4", label: 'C4 - Conservative Surgery with bilateral implants' },
        { value: "5", label: 'M1 - Mastectomy with unilateral reconstruction with implant' },
        { value: "6", label: 'M2 - Mastectomy with unilateral reconstruction with autologous flap' },
        { value: "7", label: 'M3 - Mastectomy with bilateral reconstruction with implants' },
        { value: "8", label: 'M4 - Mastectomy with bilateral reconstruction with autologous flaps' },
        { value: "9", label: 'M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)' },
        { value: "10", label: 'M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction' },
        { value: "11", label: 'M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction' },
        { value: "12", label: 'M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)' },
    ]

    const options2 = [
        { value: "5", label: 'M1 - Mastectomy with unilateral reconstruction with implant' },
        { value: "6", label: 'M2 - Mastectomy with unilateral reconstruction with autologous flap' },
        { value: "7", label: 'M3 - Mastectomy with bilateral reconstruction with implants' },
        { value: "8", label: 'M4 - Mastectomy with bilateral reconstruction with autologous flaps' },
        { value: "9", label: 'M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)' },
        { value: "10", label: 'M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction' },
        { value: "11", label: 'M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction' },
        { value: "12", label: 'M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)' },
    ]

    const options3 = [
        { value: "13", label: 'P1 - Fat Grafting' },
        { value: "14", label: 'P2 - Scar Revision' },
        { value: "15", label: 'P3 - Nipple Reconstruction' },
        { value: "16", label: 'P4a - Unilateral Delayed Reconstruction with autolugous flap' },
        { value: "17", label: 'P4b - Bilateral Delayed Reconstruction with autolugous flap' },
        { value: "18", label: 'P5a - Unilateral Delayed Reconstruction with implant' },
        { value: "19", label: 'P5b - Bilateral Delayed Reconstruction with implant' },
        { value: "20", label: 'P6a - Unilateral Capsulectomy and implant replacement' },
        { value: "21", label: 'P6b - Bilateral Capsulectomy and implant replacement' },
        { value: "22", label: 'P7a - Uniloateral Capsulectomy and implant removal' },
        { value: "23", label: 'P7b - Biloateral Capsulectomy and implant removal' },
        { value: "24", label: 'P8a - Unilateral Reduction' },
        { value: "28", label: 'P8b - Bilateral Reduction' }
    ]

    const options4 = [
        { value: "1", label: "Cancer" },
        { value: "2", label: "Prophylatic" },
        { value: "3", label: "Improvement" },
        { value: "4", label: "Benign" }
    ]

    const options5 = [
        { value: "1", label: 'Left' },
        { value: "2", label: 'Right' },
    ]

    const [date, setDate] = useState(location.state.date ? location.state.date : undefined)
    const updateDate = (date) => {
        setDate(date)
        setErrors({})
    }

    let createSurgery = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + id + '/add/surgery', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: JSON.stringify({
                "date": date,
                "surgery_type": surgeryType,
                "surgical_indication": surgicalIndication,
                "institutional_number": institutional_number,
                "sides": cancerSide
            })
        })
        let data = await response
        if (response.status === 200) {
            history.push({ pathname: generatePath("/patient/:id", { id: id }), state: { id: id } })
        }
        else if (response.status == 400) {
            setErrors({ date: response.data['date'] })
        }
    }

    useEffect(() => {
    }, [surgicalIndication,])

    return (
        <Container className='px-4 my-2'>

            <Breadcrumb>
                {page_info === null ? <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item> : page_info.source === "home" ? <Breadcrumb.Item onClick={() => { history.push({ pathname: generatePath("/:username/patients", { username: user.username }), state: { page_info: page_info } }) }}>Patients</Breadcrumb.Item> : page_info.source === "dashboard" ? <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/:username/dashboard", { username: user.username }), state: { page_info: page_info } })}>Dashboard</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>}
                <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/patient/:id", { id: id }), state: { id: id } })}>
                    Patient {institutional_number} Profile
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Add Surgery Patient {institutional_number}
                </Breadcrumb.Item>
            </Breadcrumb>

            <h1> Add Surgery Patient {institutional_number}</h1>

            <Container>
                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>Surgery Date</Form.Label>
                        <Form.Control name={"surgery_date"} type="date" onChange={(e) => updateDate(e.target.value)} />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>Surgical Indication</Form.Label>
                        <Select isMulti options={options4} className="basic-multi-select w-100" classNamePrefix="select" name={"surgical_indication"}
                            onChange={(e) => {
                                surgicalIndication.length = 0
                                let aux2 = []
                                e.map((aux) => { aux2.push(parseInt(aux.value)) })
                                setSurgicalIndication(aux2)
                            }
                            }
                        />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>Surgery Type</Form.Label>
                        {
                            surgicalIndication.length != 0 ?
                                <Select
                                    isMulti
                                    options={(surgicalIndication.includes(1) || surgicalIndication.includes(4)) && surgicalIndication.includes(2) && surgicalIndication.includes(3) ? options1.concat(options3) :
                                        (surgicalIndication.includes(1) || surgicalIndication.includes(4)) && surgicalIndication.includes(2) && !surgicalIndication.includes(3) ? options1 :
                                            (surgicalIndication.includes(1) || surgicalIndication.includes(4)) && !surgicalIndication.includes(2) && surgicalIndication.includes(3) ? options1.concat(options3) :
                                                !surgicalIndication.includes(1) && !surgicalIndication.includes(4) && surgicalIndication.includes(2) && surgicalIndication.includes(3) ? options2.concat(options3) :
                                                    (surgicalIndication.includes(1) || surgicalIndication.includes(4)) && !surgicalIndication.includes(2) && !surgicalIndication.includes(3) ? options1 :
                                                        !surgicalIndication.includes(1) && !surgicalIndication.includes(4) && surgicalIndication.includes(2) && !surgicalIndication.includes(3) ? options2 :
                                                            !surgicalIndication.includes(1) && !surgicalIndication.includes(4) && !surgicalIndication.includes(2) && surgicalIndication.includes(3) ? options3 :
                                                                null
                                    }
                                    className="basic-multi-select w-100"
                                    classNamePrefix="select"
                                    name={"surgery_type"}
                                    onChange={(e) => {
                                        surgeryType.length = 0
                                        e.map((aux) => { surgeryType.push(parseInt(aux.value)) })
                                    }
                                    }
                                />
                                :
                                <Select isDisabled={true} />
                        }
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className='mb-3'>
                        <Form.Label>Cancer Side</Form.Label>
                        <Select isMulti options={options5} className="basic-multi-select w-100" classNamePrefix="select" name={"cancer_sides"}
                            onChange={(e) => {
                                cancerSide.length = 0
                                e.map((aux) => { cancerSide.push(parseInt(aux.value)) })
                            }} />
                    </Form.Group>
                </Col>


                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button className='FilledButton' onClick={createSurgery} >Submit</Button>
                </Col>
            </Container>
        </Container >

    )
}

export default withRouter(CreateSurgeryPage)