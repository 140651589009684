import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext';
import history from '../history';
import { withRouter, generatePath } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'bootstrap';

import Select from 'react-select'



const PatientProfilePage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const location = useLocation()

    let patient_id = location.state.id
    let page_info = location.state.page !== undefined ? location.state.page : null

    const [appState, setAppState] = useState({
        isLoading: true,
        patient: null,
    })

    const [errors, setErrors] = useState({})

    const [editCoreInfo, setEditCoreInfo] = useState(false)

    const [editHadRtBoost, setEditHadRtBoost] = useState(false)

    const [editHadRt, setEditHadRt] = useState(false)

    const [editSurgeries, setEditSurgeries] = useState([])

    const [editRadiotherapies, setEditRadiotherapies] = useState([])

    const [newSurgeryTypes, setNewSurgeryTypes] = useState([])

    const [newCancerSides, setNewCancerSides] = useState([])

    const [newProjects, setNewProjects] = useState([])

    const [defaultSurgeryValues, setDefaultSurgeryValues] = useState([])

    const [defaultCancerSideValues, setDefaultCancerSideValues] = useState([])

    const [defaultProjectsValues, setDefaultProjectsValues] = useState([])

    const [activeRtKey, setActiveRtKey] = useState(null)

    const [activeSurgeryKey, setActiveSurgeryKey] = useState("surgery1")

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const [hasProject, setHasProject] = useState(false)

    const options1 = [
        { value: "1", label: 'C1 - Conservative Surgery' },
        { value: "2", label: 'C2 - Conservative Surgery with bilateral reduction' },
        { value: "3", label: 'C3 - Conservative Surgery with LD or LICAC/TDAP flaps' },
        { value: "4", label: 'C4 - Conservative Surgery with bilateral implants' },
        { value: "5", label: 'M1 - Mastectomy with unilateral reconstruction with implant' },
        { value: "6", label: 'M2 - Mastectomy with unilateral reconstruction with autologous flap' },
        { value: "7", label: 'M3 - Mastectomy with bilateral reconstruction with implants' },
        { value: "8", label: 'M4 - Mastectomy with bilateral reconstruction with autologous flaps' },
        { value: "9", label: 'M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)' },
        { value: "10", label: 'M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction' },
        { value: "11", label: 'M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction' },
        { value: "12", label: 'M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)' },
    ]

    const options2 = [
        { value: "5", label: 'M1 - Mastectomy with unilateral reconstruction with implant' },
        { value: "6", label: 'M2 - Mastectomy with unilateral reconstruction with autologous flap' },
        { value: "7", label: 'M3 - Mastectomy with bilateral reconstruction with implants' },
        { value: "8", label: 'M4 - Mastectomy with bilateral reconstruction with autologous flaps' },
        { value: "9", label: 'M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)' },
        { value: "10", label: 'M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction' },
        { value: "11", label: 'M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction' },
        { value: "12", label: 'M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)' },
    ]

    const options3 = [
        { value: "13", label: 'P1 - Fat Grafting' },
        { value: "14", label: 'P2 - Scar Revision' },
        { value: "15", label: 'P3 - Nipple Reconstruction' },
        { value: "16", label: 'P4a - Unilateral Delayed Reconstruction with autolugous flap' },
        { value: "17", label: 'P4b - Bilateral Delayed Reconstruction with autolugous flap' },
        { value: "18", label: 'P5a - Unilateral Delayed Reconstruction with implant' },
        { value: "19", label: 'P5b - Bilateral Delayed Reconstruction with implant' },
        { value: "20", label: 'P6a - Unilateral Capsulectomy and implant replacement' },
        { value: "21", label: 'P6b - Bilateral Capsulectomy and implant replacement' },
        { value: "22", label: 'P7a - Uniloateral Capsulectomy and implant removal' },
        { value: "23", label: 'P7b - Biloateral Capsulectomy and implant removal' },
        { value: "24", label: 'P8a - Unilateral Reduction' },
        { value: "28", label: 'P8b - Bilateral Reduction' }
    ]

    const options4 = [
        { value: "1", label: 'Left' },
        { value: "2", label: 'Right' },
    ]

    const options5 = [
        { value: '1', label: 'CINDERELLA' },
        { value: '0', label: 'Other' }
    ]

    let getPatientInfo = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
        })
        let data = await response.json()
        if (response.status === 200) {

            data['info'] = data['info'].sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });

            setAppState({
                isLoading: false,
                patient: data,
            })

            setEditHadRtBoost(data.had_rt_boost)

            setEditHadRt(data.had_radiotherapy)

            setActiveRtKey("creation" + data.info[0].id)

            if (data.projects.length != 0) {
                getDefaultProjects(data)
                setHasProject(true)
            }
        }
    }

    let getDefaultProjects = (patient) => {
        newProjects.length = 0

        if (patient.projects != []) {
            patient.projects.map((project) => {
                options5.map((option) => {
                    if (project == option.value) {
                        defaultProjectsValues.push(option)
                        newProjects.push(option.value)
                    }
                })
            })
        }
    }

    let deletePatient = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/delete', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: JSON.stringify({
                'institutional_number': appState.patient.institutional_number,
            })
        })
        let data = await response
        if (response.status === 200) {
            history.push("/")
        }

    }

    let getClassificationText = (classification) => {
        if (classification === null) {
            return (
                <div>
                    <h5>Unknown</h5>
                </div>
            )
        }
        else if (classification === 1) {
            return (
                <div>
                    <h5>Excellent</h5>
                </div>
            )
        }
        else if (classification === 2) {
            return (
                <div>
                    <h5>Good</h5>
                </div>
            )
        }
        else if (classification === 3) {
            return (
                <div>
                    <h5>Fair</h5>
                </div>
            )
        }
        else if (classification === 4) {
            return (
                <div>
                    <h5>Poor</h5>
                </div>
            )
        }
    }

    let getPatientStatus = (patient_status) => {
        if (patient_status === null) {
            return (
                <span> Error</span>
            )
        }
        else if (patient_status === 1) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                </div>
            )
        }
        else if (patient_status === 5) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
                </div>
            )
        }
        else if (patient_status === 7) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos</Badge>
                </div>
            )
        }
        else if (patient_status === 8) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'green' }}>Patient has all the required information, but isn't CINDERELLA.</Badge>
                </div>
            )
        }
        else if (patient_status === 9) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'purple' }}>Patient has all the required information for CINDERELLA.</Badge>
                </div>
            )
        }
        else if (patient_status === 10) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'magenta' }}>Patient does not have a complete surgery</Badge>
                </div>
            )
        }
        else if (patient_status === 12) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: '#F091AD' }}>Patient does not have post surgery photo moment complete or classification or post 1 year photos</Badge>
                </h2>
            )
        }
    }

    let updatePatient = async (type, aux, num) => {
        if (type === 1) {
            let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + appState.patient.id + '/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access
                },
                body: JSON.stringify({
                    'type': 1,
                    'institutional_number': appState.patient.institutional_number,
                    'projects': newProjects,
                })
            })
            let data = await response
            if (response.status === 200) {
                setEditCoreInfo(!editCoreInfo)
                getPatientInfo()
                defaultProjectsValues.length = 0
            }
            else if (response.status === 409) {
                setErrors({ "institutional_number": "A patient with that insitutional number already exists." })
            }
            else if (response.status === 410) {
                setErrors({ "institutional_number": "An insitutional number is required." })
            }
            else if (response.status === 411) {
                setErrors({ "birthday": "A birthday is required." })
            }
        }
        else if (type == 2) {
            let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + appState.patient.id + '/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access
                },
                body: JSON.stringify({
                    'type': 2,
                    'start_date': appState.patient.radiotherapies_info[num - 1].start_date,
                    'end_date': appState.patient.radiotherapies_info[num - 1].end_date,
                    'total_dose': appState.patient.radiotherapies_info[num - 1].total_dose,
                    'radiotherapy_boost': appState.patient.radiotherapies_info[num - 1].radiotherapy_boost,
                    'id': appState.patient.radiotherapies_info[num - 1].id,
                    'boost_dose': appState.patient.radiotherapies_info[num - 1].boost_dose,
                })
            })
            let data = await response
            if (response.status === 200) {
                setEditRadiotherapies(aux)
                getPatientInfo()
            }
        }
        else if (type == 3) {
            let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + appState.patient.id + '/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access
                },
                body: JSON.stringify({
                    'type': 3,
                    'bra_size': appState.patient.surgeries_info[num - 1].bra_size,
                    'bra_cup': appState.patient.surgeries_info[num - 1].bra_cup,
                    'weight': appState.patient.surgeries_info[num - 1].weight,
                    'surgery_date': appState.patient.surgeries_info[num - 1].date,
                    'surgery_type': newSurgeryTypes,
                    'surgical_indication': appState.patient.surgeries_info[num - 1].surgical_indication,
                    'id': appState.patient.surgeries_info[num - 1].id,
                    'height': appState.patient.surgeries_info[num - 1].height,
                    'cancer_side': newCancerSides,
                })
            })
            let data = await response
            if (response.status === 200) {
                defaultSurgeryValues.length = 0
                defaultCancerSideValues.length = 0
                setEditSurgeries(aux)
                getPatientInfo()
            }
        }
        else {
            return null
        }
    }

    useEffect(() => {
        getPatientInfo()
    }, [setAppState,])

    if (appState.isLoading === true) {
        return (
            <Container className='text-center'>
                <div style={{ marginTop: '22.5%', }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Container>
        )
    }
    else {
        return (
            <Container style={{ minWidth: "85vw" }}>
                <Breadcrumb>
                    {page_info === null ? <Breadcrumb.Item>Patients</Breadcrumb.Item> : page_info.source === "home" ? <Breadcrumb.Item onClick={() => { history.push({ pathname: generatePath("/:username/patients", { username: user.username }), state: { page_info: page_info } }) }}>Patients</Breadcrumb.Item> : page_info.source === "dashboard" ? <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/:username/dashboard", { username: user.username }), state: { page_info: page_info } })}>Dashboard</Breadcrumb.Item> : <Breadcrumb.Item>Patients</Breadcrumb.Item>}
                    <Breadcrumb.Item active>
                        Patient {appState.patient.institutional_number} Profile
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Container>
                    <Container>
                        <Row className="responsiveRow" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col>
                                <h3 className='mt-3' style={{ color: "#444444" }}><strong>Patient: </strong>{appState.patient.institutional_number}</h3>
                            </Col>

                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                {editCoreInfo === false ?
                                    <h3 className='mt-3' style={{ color: "#444444" }}><strong>Projects: </strong>{appState.patient.projects}</h3>
                                    :
                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                        <Select
                                            isMulti
                                            options={options5}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name={"editProjects"}
                                            defaultValue={defaultProjectsValues}
                                            onChange={(e) => {
                                                newProjects.length = 0
                                                e.map((aux) => { newProjects.push(parseInt(aux.value)) })
                                            }
                                            }
                                        />
                                    </div>
                                }
                            </Col>

                            <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                {editCoreInfo === false ?
                                    <Button onClick={() => setEditCoreInfo(!editCoreInfo)} variant="primary" className="my-auto roundButton mt-3" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                    :
                                    <Button onClick={() => updatePatient(1, null)} variant="primary" className="my-auto roundButton mt-3"><i className="fa-solid fa-floppy-disk"></i></Button>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '2vh' }}>
                                <h4>
                                    {
                                        getPatientStatus(appState.patient.status)
                                    }
                                </h4>
                            </Col>
                        </Row>
                    </Container>
                    <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                    <Container style={{ minHeight: "50vh" }}>
                        <Tabs className="mb-3 responsiveRow" justify onSelect={(e) => setActiveRtKey(e)} activeKey={activeRtKey}>
                            {
                                appState.patient.info.map(information => (
                                    <Tab eventKey={information.action + information.id} title={information.action == "creation" ? "Creation" : information.action == "surgery" ? information.date + " - Surgery" :
                                        information.action == "radiotherapy" ? information.date + " - Radiotherapy" : information.action == "photograph" ? information.date + " - Photographs" : null}
                                        key={information.action + information.id}>
                                        {
                                            information.action == "creation" ?
                                                <Container style={{ minHeight: "40vh", overflow: 'scroll' }}>
                                                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Col>
                                                            <h3 className='mt-0' style={{ color: "#444444" }}>Basic Information</h3>
                                                        </Col>
                                                        <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                            <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/edit/:id2", { id: patient_id, id2: information.id }), state: { info: information, page: page_info, patient_id: patient_id } })} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className='px-0 my-2'>
                                                        <Col>
                                                            <h4 style={{ color: "#444444" }}><strong>Birthday: </strong> {information.date}</h4>
                                                        </Col>
                                                    </Row>
                                                    <Row className='px-0 my-2'>
                                                        <Col>
                                                            <h4 style={{ color: "#444444" }}><strong>Height: </strong> {information.height}</h4>
                                                        </Col>
                                                        <Col>
                                                            <h4 style={{ color: "#444444" }}><strong>Weight: </strong> {information.weight}</h4>
                                                        </Col>
                                                    </Row>
                                                    <Row className='px-0 my-2'>
                                                        <Col>
                                                            <h4 style={{ color: "#444444" }}><strong>Bra Size: </strong> {information.bra_size}</h4>
                                                        </Col>
                                                        <Col>
                                                            <h4 style={{ color: "#444444" }}><strong>Bra Cup: </strong> {information.bra_cup}</h4>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                                :
                                                information.action == "radiotherapy" ?
                                                    <Container style={{ minHeight: "40vh", overflow: 'scroll' }}>
                                                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Col>
                                                                <h3 className='mt-0' style={{ color: "#444444" }}>{"Radiotherapy Information"}</h3>
                                                            </Col>
                                                            <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                                <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/edit/:id2", { id: patient_id, id2: information.id }), state: { info: information, page: page_info, patient_id: patient_id } })} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className='px-0 my-2'>
                                                            <Col>
                                                                <h4 style={{ color: "#444444" }}><strong>Radiotherapy start date: </strong> {information.date}</h4>
                                                            </Col>
                                                            <Col>
                                                                <h4 style={{ color: "#444444" }}><strong>Radiotherapy end date: </strong> {information.end_date}</h4>
                                                            </Col>
                                                        </Row>
                                                        <Row className='px-0 my-2'>
                                                            <Col>
                                                                <h4 style={{ color: "#444444" }}><strong>Total Dose: </strong> {information.total_dose}</h4>
                                                            </Col>
                                                            <Col>
                                                                <h4 style={{ color: "#444444" }}><strong>Boost Dose: </strong> {information.boost_dose}</h4>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <h4 style={{ color: "#444444" }}><strong>Follow-up time from radiotherapy: </strong>{information.fup_rt_years && information.fup_rt_months ? information.fup_rt_years + " year and " + information.fup_rt_months + " months" :
                                                                information.fup_rt_years && !information.fup_rt_months ? information.fup_rt_years + " years" :
                                                                    information.fup_rt_months + " months"}
                                                            </h4>
                                                        </Row>
                                                    </Container>
                                                    :
                                                    information.action == "surgery" ?
                                                        <Container style={{ minHeight: "40vh", overflow: 'scroll' }}>
                                                            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Col>
                                                                    <h3 className='mt-0' style={{ color: "#444444" }}>{"Surgery Information"}</h3>
                                                                </Col>
                                                                <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                                    <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/edit/:id2", { id: patient_id, id2: information.id }), state: { info: information, page: page_info, patient_id: patient_id } })} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                                </Col>
                                                            </Row>
                                                            <Row className='px-0 my-2'>
                                                                <Col>
                                                                    <h4 style={{ color: "#444444" }}><strong>Surgery Date: </strong> {information.date}</h4>
                                                                </Col>
                                                                <Col>
                                                                    <h4 style={{ color: "#444444" }}><strong>Follow-up time from surgery: </strong>{information.fup_surgery_years && information.fup_surgery_months ? information.fup_surgery_years + " year and " + information.fup_surgery_months + " months" :
                                                                        information.fup_surgery_years && !information.fup_surgery_months ? information.fup_surgery_years + " years" :
                                                                            information.fup_surgery_months + " months"}
                                                                    </h4>
                                                                </Col>
                                                            </Row>
                                                            <Row className='px-0 my-2'>
                                                                <Col>
                                                                    <h4 style={{ color: "#444444" }}><strong>Surgery Indication: </strong>
                                                                        {
                                                                            information.surgical_indication !== null ?
                                                                                information.surgical_indication.map((type) => {
                                                                                    return <span key={parseInt(type)}>
                                                                                        {
                                                                                            parseInt(type) === 1 ? "Cancer" :
                                                                                                parseInt(type) === 2 ? "Prophylatic" :
                                                                                                    parseInt(type) === 3 ? "Improvement" :
                                                                                                        parseInt(type) === 4 ? "Benign" :
                                                                                                            null
                                                                                        }
                                                                                    </span>
                                                                                })
                                                                                :
                                                                                null
                                                                        }
                                                                    </h4>
                                                                </Col>
                                                                <Col>
                                                                    <h4 style={{ color: "#444444" }}><strong>Surgery type: </strong>
                                                                        {
                                                                            information.surgery_type.map((type) => {
                                                                                return <span key={parseInt(type)}>
                                                                                    {
                                                                                        parseInt(type) === 1 ? "C1 - Conservative Surgery" :
                                                                                            parseInt(type) === 2 ? "C2 - Conservative Surgery with bilateral reduction" :
                                                                                                parseInt(type) === 3 ? "C3 - Conservative Surgery with LD or LICAC/TDAP flaps" :
                                                                                                    parseInt(type) === 4 ? "C4 - Conservative Surgery with bilateral implants" :
                                                                                                        parseInt(type) === 5 ? "M1 - Mastectomy with unilateral reconstruction with implant" :
                                                                                                            parseInt(type) === 6 ? "M2 - Mastectomy with unilateral reconstruction with autologous flap" :
                                                                                                                parseInt(type) === 7 ? "M3 - Mastectomy with bilateral reconstruction with implants" :
                                                                                                                    parseInt(type) === 8 ? "M4 - Mastectomy with bilateral reconstruction with autologous flaps" :
                                                                                                                        parseInt(type) === 9 ? "M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)" :
                                                                                                                            parseInt(type) === 10 ? "M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction" :
                                                                                                                                parseInt(type) === 11 ? "M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction" :
                                                                                                                                    parseInt(type) === 12 ? "M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)" :
                                                                                                                                        parseInt(type) === 13 ? "P1 - Fat Grafting" :
                                                                                                                                            parseInt(type) === 14 ? "P2 - Scar Revision" :
                                                                                                                                                parseInt(type) === 15 ? "P3 - Nipple Reconstruction" :
                                                                                                                                                    parseInt(type) === 16 ? "P4a - Unilateral Delayed Reconstruction with autolugous flap" :
                                                                                                                                                        parseInt(type) === 17 ? "P4b - Bilateral Delayed Reconstruction with autolugous flap" :
                                                                                                                                                            parseInt(type) === 18 ? "P5a - Unilateral Delayed Reconstruction with implant" :
                                                                                                                                                                parseInt(type) === 19 ? "P5b - Bilateral Delayed Reconstruction with implant" :
                                                                                                                                                                    parseInt(type) === 20 ? "P6a - Unilateral Capsulectomy and implant replacement" :
                                                                                                                                                                        parseInt(type) === 21 ? "P6b - Bilateral Capsulectomy and implant replacement" :
                                                                                                                                                                            parseInt(type) === 22 ? "P7a - Uniloateral Capsulectomy and implant removal" :
                                                                                                                                                                                parseInt(type) === 23 ? "P7b - Biloateral Capsulectomy and implant removal" :
                                                                                                                                                                                    parseInt(type) === 24 ? "P8a - Unilateral Reduction" :
                                                                                                                                                                                        parseInt(type) === 28 ? "P8b - Bilateral Reduction" :
                                                                                                                                                                                            parseInt(type) === 25 ? "C5 - Margin Widening" :
                                                                                                                                                                                                parseInt(type) === 26 ? "M9a - Simple Mastectomy" :
                                                                                                                                                                                                    parseInt(type) === 27 ? "M9b - Simple Mastectomy with contralateral reduction" :
                                                                                                                                                                                                        null
                                                                                    }

                                                                                </span>
                                                                            })
                                                                        }
                                                                    </h4>
                                                                </Col>
                                                            </Row>
                                                            <Row className='px-0 my-2'>
                                                                <Col>
                                                                    <h4 style={{ color: "#444444" }}><strong>Sides: </strong> {information.cancer_sides == 1 ? "Left" :
                                                                        information.cancer_sides == 2 ? "Right" :
                                                                            information.cancer_sides == 3 ? "Bilateral" :
                                                                                null}</h4>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                        :
                                                        information.action == "photo" ?
                                                            <Container>
                                                                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Col>
                                                                        <h3 className='mt-0' style={{ color: "#444444" }}>{"Photos set number " + information.id}</h3>
                                                                    </Col>
                                                                    <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                                        <Button onClick={() => console.log("YO")} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                                    </Col>
                                                                </Row>
                                                                <Card>
                                                                    <Card.Header className="text-center card-header-text" as="h5">
                                                                        Classification: {getClassificationText(information.classification)}
                                                                        {information.classification_type === null ?
                                                                            null
                                                                            :
                                                                            information.classification_type === 1 ?
                                                                                <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Subjective Classification</Tooltip>}>
                                                                                    <i className="fas fa-circle" aria-hidden="true" style={{ color: 'red' }} />
                                                                                </OverlayTrigger>
                                                                                :
                                                                                <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Objective Classification</Tooltip>}>
                                                                                    <i className="fas fa-circle" aria-hidden="true" style={{ color: 'blue' }} />
                                                                                </OverlayTrigger>
                                                                        }
                                                                    </Card.Header>
                                                                    {
                                                                        information.favorite_image !== null ?
                                                                            <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + information.favorite_image} width="250" height="250" />
                                                                            :
                                                                            null
                                                                    }
                                                                    <Card.Body>
                                                                        <div className='my-2 text-center'>
                                                                            {
                                                                                (information.pre_surgery) ?
                                                                                    <span>Pre-surgery</span> :
                                                                                    (information.post_surgery && information.fup_years && information.fup_months) ?
                                                                                        <span>Post-surgery | Follow-up time: {information.fup_years} years and {information.fup_months} months</span> :
                                                                                        (information.post_surgery && information.fup_years && !information.fup_months) ?
                                                                                            <span>Post-surgery | Follow-up time: {information.fup_years} years</span> :
                                                                                            (information.post_surgery && !information.fup_years && information.fup_months) ?
                                                                                                <span>Post-surgery | Follow-up time: {information.fup_months} months</span> :
                                                                                                <span>Post-surgery | Follow-up time: less than 1 month</span>
                                                                            }
                                                                        </div>
                                                                        <div className='text-center'>
                                                                            <Button className='FilledButton' onClick={() => history.push({ pathname: generatePath("/patient/:id/photos/:date", { id: appState.patient.id, date: information.date }), state: { patient_id: appState.patient.id, date: information.date, page: page_info, institutional_number: appState.patient.institutional_number, pre_surgery: information.pre_surgery } })} ><i className="fa fa-images" aria-hidden="true" ></i> {information.date} ({information.num_photos}) </Button>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Container>
                                                            :
                                                            information.action == "photograph" ?
                                                                <Container style={{ minHeight: "40vh", overflow: 'scroll' }}>
                                                                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Col>
                                                                            <h3 className='mt-0' style={{ color: "#444444" }}>{"Photograph Moment Info"}</h3>
                                                                        </Col>
                                                                        <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                                            <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/edit/:id2", { id: patient_id, id2: information.id }), state: { info: information, page: page_info, patient_id: patient_id } })} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                                        </Col>
                                                                    </Row>                                                                    <Row className='px-0 my-2'>
                                                                        <Col>
                                                                            <h4 style={{ color: "#444444" }}><strong>Acquisition Date: </strong> {information.date}</h4>
                                                                        </Col>
                                                                        <Col>
                                                                            <h4 style={{ color: "#444444" }}><strong>Height: </strong> {information.height}</h4>
                                                                        </Col>
                                                                        <Col>
                                                                            <h4 style={{ color: "#444444" }}><strong>Weight: </strong>{information.weight}</h4>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='px-0 my-2'>
                                                                        <Col>
                                                                            <h4 style={{ color: "#444444" }}><strong>Bra Size: </strong> {information.bra_size}</h4>
                                                                        </Col>
                                                                        <Col>
                                                                            <h4 style={{ color: "#444444" }}><strong>Bra Cup: </strong>{information.bra_cup}</h4>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <Card className="patientCard" style={{ width: "13vw" }}>
                                                                                <Card.Header className="text-center card-header-text" as="h5">
                                                                                    Classification: {getClassificationText(information.classification)}
                                                                                    {information.classification_type === null ?
                                                                                        null
                                                                                        :
                                                                                        information.classification_type === 1 ?
                                                                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Subjective Classification</Tooltip>}>
                                                                                                <i className="fas fa-circle" aria-hidden="true" style={{ color: 'red' }} />
                                                                                            </OverlayTrigger>
                                                                                            :
                                                                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Objective Classification</Tooltip>}>
                                                                                                <i className="fas fa-circle" aria-hidden="true" style={{ color: 'blue' }} />
                                                                                            </OverlayTrigger>
                                                                                    }
                                                                                </Card.Header>
                                                                                {
                                                                                    information.favorite_image_path !== null ?
                                                                                        <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + information.favorite_image_path} width="150" height="150" />
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <Card.Body>
                                                                                    <div className='my-2 text-center'>
                                                                                        {
                                                                                            (information.pre_or_post == "pre") ?
                                                                                                <span>Pre-surgery</span> :
                                                                                                (information.pre_or_post == "post" && information.fup_years && information.fup_months) ?
                                                                                                    <span>Post-surgery | Follow-up time: {information.fup_years} years and {information.fup_months} months</span> :
                                                                                                    (information.pre_or_post == "post" && information.fup_years && !information.fup_months) ?
                                                                                                        <span>Post-surgery | Follow-up time: {information.fup_years} years</span> :
                                                                                                        (information.pre_or_post == "post" && !information.fup_years && information.fup_months) ?
                                                                                                            <span>Post-surgery | Follow-up time: {information.fup_months} months</span> :
                                                                                                            <span>Post-surgery | Follow-up time: less than 1 month</span>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='text-center'>
                                                                                        <Button className='FilledButton' onClick={() => history.push({ pathname: generatePath("/patient/:id/photographs/:date", { id: appState.patient.id, date: information.date }), state: { patient_id: appState.patient.id, date: information.date, page: page_info, institutional_number: appState.patient.institutional_number, pre_surgery: information.pre_or_post == "pre" ? true : false } })} ><i className="fa fa-images" aria-hidden="true" ></i> {information.date} ({information.num_photos}) </Button>
                                                                                    </div>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                                :
                                                                null
                                        }

                                    </Tab>
                                ))
                            }

                        </Tabs>
                    </Container>
                    <Container className='mt-3 mb-5'>
                        <Row className="responsiveRow" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/upload", { id: patient_id }), state: { patient: appState.patient, page: page_info, } })} variant="outline-primary" className="addPatient-button patientOption-button" >Add Photograph Moment</Button>
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/addSurgery", { id: patient_id }), state: { patient: appState.patient, page: page_info, } })} variant="outline-primary" className="addPatient-button patientOption-button" >Add Surgery</Button>
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/addRadiotherapy", { id: patient_id }), state: { patient: appState.patient, page: page_info, } })} variant="outline-primary" className="addPatient-button patientOption-button" >Add Radiotherapy</Button>
                            </Col>
                        </Row>
                    </Container>
                    <div className='addPatient-div'>
                        <Button variant="outline-primary" className="deletePatient-button my-auto" onClick={() => handleShow()}><i className="fa fa-trash" aria-hidden="true"></i> Delete Patient </Button>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Patient {patient_id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete this patient?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={() => deletePatient()}>
                                    Confirm
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </Container>
            </Container>
        )
    }
    /*else {
        return (
            <Container style={{ minWidth: "80vw" }}>
                <Breadcrumb>
                    {page_info === null ? <Breadcrumb.Item>Patients</Breadcrumb.Item> : page_info.source === "home" ? <Breadcrumb.Item onClick={() => { history.push({ pathname: generatePath("/:username/patients", { username: user.username }), state: { page_info: page_info } }) }}>Patients</Breadcrumb.Item> : page_info.source === "dashboard" ? <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/:username/dashboard", { username: user.username }), state: { page_info: page_info } })}>Dashboard</Breadcrumb.Item> : <Breadcrumb.Item>Patients</Breadcrumb.Item>}
                    <Breadcrumb.Item active>
                        Patient {appState.patient.institutional_number} Profile
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Container className='px-4'>
                    <Container>
                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col>
                                {editCoreInfo === false ?
                                    <h2 className='mt-3' style={{ color: "#444444" }}><strong>Patient: </strong>{appState.patient.institutional_number}</h2>
                                    :
                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                        <Form.Label style={{ color: "#444444" }} htmlFor="editInstitutionalNumber" as="h2"><strong>Patient: </strong></Form.Label>
                                        <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="text" id="editInstitutionalNumber" defaultValue={appState.patient.institutional_number} isInvalid={!!errors.institutional_number} onChange={(e) => { handleUpdateCoreInfo("institutional_number", e.target.value) }} />
                                        <Form.Control.Feedback type="invalid">{errors.institutional_number}</Form.Control.Feedback>
                                    </div>
                                }
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                {editCoreInfo === false ?
                                    <h2 className='mt-3' style={{ color: "#444444" }}><strong>Age: </strong>{appState.patient.age}</h2>
                                    :
                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                        <Form.Label style={{ color: "#444444", fontWeight: 'bold' }} htmlFor="editAge" as="h2">Birthday:</Form.Label>
                                        <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="date" id="editAge" defaultValue={appState.patient.birthday} isInvalid={!!errors.birthday} onChange={(e) => { handleUpdateCoreInfo("birthday", e.target.value) }} />
                                        <Form.Control.Feedback type="invalid">{errors.birthday}</Form.Control.Feedback>
                                    </div>
                                }
                            </Col>

                            {editCoreInfo === false ?
                                null
                                :
                                <Col>
                                    <Form.Group style={{ display: "flex", justifyContent: "center" }} className='mt-4'>
                                        <Form.Check type="checkbox" name="project" label="Is part of a project?" onClick={() => { setHasProject(current => !current); }} defaultChecked={appState.patient.projects.length != 0 ? true : false} />
                                    </Form.Group>
                                </Col>
                            }

                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                {editCoreInfo === false ?
                                    <h2 className='mt-3' style={{ color: "#444444" }}><strong>Projects: </strong>{appState.patient.projects}</h2>
                                    :
                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                        <Select
                                            isMulti
                                            options={options5}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name={"editProjects"}
                                            defaultValue={defaultProjectsValues}
                                            onChange={(e) => {
                                                newProjects.length = 0
                                                e.map((aux) => { newProjects.push(parseInt(aux.value)) })
                                            }
                                            }
                                        />
                                    </div>
                                }
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                {editCoreInfo === false ?
                                    <Button onClick={() => setEditCoreInfo(!editCoreInfo)} variant="primary" className="my-auto roundButton mt-3" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                    :
                                    <Button onClick={() => updatePatient(1, null)} variant="primary" className="my-auto roundButton mt-3"><i className="fa-solid fa-floppy-disk"></i></Button>
                                }
                            </Col>
                        </Row>
                        <Container>
                            <Row className='px-0 text-center'>
                                <Col className='px-0'>

                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <Container>
                        <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                        <h2 className='mt-3' style={{ color: "#444444" }}>Patient Status</h2>
                        <h3>
                            {
                                getPatientStatus(appState.patient.status)
                            }
                        </h3>
                        <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                    </Container>
                    <Container className='mt-2'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h2 className='mt-3' style={{ color: "#444444" }}>Radiotherapy</h2>
                            <Button onClick={() => { addRadiotherapy() }} variant="primary" className="my-auto roundButton" ><i className="fas fa-plus" aria-hidden="true"></i></Button>
                        </div>
                        <Tabs
                            className="mb-3"
                            justify
                            onSelect={(e) => setActiveRtKey(e)}
                            activeKey={activeRtKey}
                        >
                            {
                                appState.patient.radiotherapies_info.map(radiotherapy => (
                                    <Tab eventKey={"radiotherapy" + radiotherapy.num} title={"Radiotherapy " + radiotherapy.num} key={radiotherapy.num}>
                                        <Container>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <h2 className='mt-0' style={{ color: "#444444" }}>Radiotherapy Information</h2>
                                                {editRadiotherapies[radiotherapy.num - 1][0] === false ?
                                                    <Button onClick={() => { let aux = editRadiotherapies.slice(); aux[radiotherapy.num - 1][0] = !aux[radiotherapy.num - 1][0]; setEditRadiotherapies(aux) }} variant="primary" className="my-auto roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                    :
                                                    <Button onClick={() => { let aux = editRadiotherapies.slice(); aux[radiotherapy.num - 1][0] = !aux[radiotherapy.num - 1][0]; updatePatient(2, aux, radiotherapy.num) }} variant="primary" className="my-auto roundButton" ><i className="fa-solid fa-floppy-disk"></i></Button>
                                                }
                                            </div>
                                            <Row className='px-0 my-2'>
                                                {editRadiotherapies[radiotherapy.num - 1][0] === false ?
                                                    <h4 style={{ color: "#444444" }}><strong>Radiotherapy start date: </strong>{radiotherapy.start_date} </h4>
                                                    :
                                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                        <Form.Label style={{ color: "#444444" }} htmlFor="editRtStartDate" as="h4"><strong>Radiotherapy start date: </strong></Form.Label>
                                                        <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="date" id="editRtStartDate" defaultValue={radiotherapy.start_date} onChange={(e) => { handleUpdateRadiotherapyInfo(radiotherapy.num - 1, "start_date", e.target.value) }} />
                                                    </div>
                                                }
                                            </Row>
                                            <Row className='px-0 my-2'>
                                                {editRadiotherapies[radiotherapy.num - 1][0] === false ?
                                                    <h4 style={{ color: "#444444" }}><strong>Radiotherapy end date: </strong>{radiotherapy.end_date}</h4>
                                                    :
                                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                        <Form.Label style={{ color: "#444444" }} htmlFor="editRtEndDate" as="h4"><strong>Radiotherapy end date: </strong></Form.Label>
                                                        <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="date" id="editRtEndDate" defaultValue={radiotherapy.end_date} onChange={(e) => { handleUpdateRadiotherapyInfo(radiotherapy.num - 1, "end_date", e.target.value) }} />
                                                    </div>
                                                }
                                            </Row>
                                            <Row className='px-0 my-2'>
                                                {editRadiotherapies[radiotherapy.num - 1][0] === false ?
                                                    <h4 style={{ color: "#444444" }}><strong>Radiotherapy Total Dose: </strong>{radiotherapy.total_dose}</h4>
                                                    :
                                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                        <Form.Label style={{ color: "#444444" }} htmlFor="editRtTotalDose" as="h4"><strong>Radiotherapy Total Dose:</strong></Form.Label>
                                                        <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type='text' id='editRtTotalDose' defaultValue={radiotherapy.total_dose} onChange={(e) => { handleUpdateRadiotherapyInfo(radiotherapy.num - 1, "total_dose", e.target.value) }} />
                                                    </div>
                                                }
                                            </Row>
                                            <Row className='px-0 my-2'>
                                                {editRadiotherapies[radiotherapy.num - 1][0] === true ?
                                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                        <Form.Label style={{ color: "#444444" }} htmlFor="editHadRtBoost" as="h4"><strong>Had Radiotherapy Boost: </strong></Form.Label>
                                                        <Form.Check className='my-1 mx-2' onClick={() => setEditHadRtBoost(!editHadRtBoost)} type='checkbox' id='editHadRtBoost' defaultChecked={radiotherapy.radiotherapy_boost} onChange={(e) => { handleUpdateRadiotherapyInfo(radiotherapy.num - 1, "radiotherapy_boost", e.target.checked) }} />
                                                    </div>
                                                    :
                                                    radiotherapy.radiotherapy_boost === true ?
                                                        <h4 style={{ color: "#444444" }}><strong>Had Radiotherapy Boost: </strong>Yes</h4>
                                                        :
                                                        <h4 style={{ color: "#444444" }}><strong>Had Radiotherapy Boost: </strong>No</h4>
                                                }
                                            </Row>
                                            {radiotherapy.radiotherapy_boost === true ?
                                                <Row className='px-0 my-2'>
                                                    {editRadiotherapies[radiotherapy.num - 1][0] === false ?
                                                        <h4 style={{ color: "#444444" }}><strong>Boost Dose: </strong>{radiotherapy.boost_dose}</h4>
                                                        :
                                                        <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                            <Form.Label style={{ color: "#444444" }} htmlFor="editRtBoostDose" as="h4"><strong>Boost Dose:</strong></Form.Label>
                                                            <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type='text' id='editRtBoostDose' defaultValue={radiotherapy.boost_dose} onChange={(e) => { handleUpdateRadiotherapyInfo(radiotherapy.num - 1, "boost_dose", e.target.value) }} />
                                                        </div>
                                                    }
                                                </Row>
                                                :
                                                null
                                            }
                                            <Row className='px-0 my-2'>
                                                {editRadiotherapies[radiotherapy.num - 1][0] === false ?
                                                    <h4 style={{ color: "#444444" }}><strong>Follow-up time from radiotherapy: </strong>{radiotherapy.fup_rt_years && radiotherapy.fup_rt_months ? radiotherapy.fup_rt_years + " year and " + radiotherapy.fup_rt_months + " months" :
                                                        radiotherapy.fup_rt_years && !radiotherapy.fup_rt_months ? radiotherapy.fup_rt_years + " years" :
                                                            radiotherapy.fup_rt_months + " months"}
                                                    </h4>
                                                    :
                                                    null
                                                }
                                            </Row>

                                            <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                                        </Container>
                                    </Tab>

                                ))
                            }
                        </Tabs>
                    </Container>
                    <Container>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h2 className='mt-3' style={{ color: "#444444" }}>Surgeries</h2>
                            <Button onClick={() => { addSurgery() }} variant="primary" className="my-auto roundButton" ><i className="fas fa-plus" aria-hidden="true"></i></Button>
                        </div>
                        <Tabs
                            className="mb-3"
                            justify
                            onSelect={(e) => setActiveSurgeryKey(e)}
                            activeKey={activeSurgeryKey}
                        >
                            {
                                appState.patient.surgeries_info.map(surgery => (
                                    <Tab eventKey={"surgery" + surgery.num} title={"Surgery " + surgery.num} key={surgery.num}>
                                        <Container>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <h2 className='mt-0' style={{ color: "#444444" }}>Surgery Information</h2>
                                                {editSurgeries[surgery.num - 1][0] === false ?
                                                    <Button onClick={() => { let aux = editSurgeries.slice(); aux[surgery.num - 1][0] = !aux[surgery.num - 1][0]; setEditSurgeries(aux); getDefaultSurgeryTypes(surgery); getDefaultCancerSides(surgery); }} variant="primary" className="my-auto roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                    :
                                                    <Button onClick={() => { let aux = editSurgeries.slice(); aux[surgery.num - 1][0] = !aux[surgery.num - 1][0]; updatePatient(3, aux, surgery.num) }} variant="primary" className="my-auto roundButton" ><i className="fa-solid fa-floppy-disk"></i></Button>
                                                }
                                            </div>
                                            <Row className='px-0 text-center my-3'>
                                                <Col className='px-0'>
                                                    {editSurgeries[surgery.num - 1][0] === false ?
                                                        <h4 style={{ color: "#444444" }}><strong>Weight: </strong>{surgery.weight ? surgery.weight + " kg" : "None"} </h4>
                                                        :
                                                        <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                            <Form.Label style={{ color: "#444444" }} htmlFor={"editWeight" + surgery.num} as="h4"><strong>Weight: </strong></Form.Label>
                                                            <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="number" id={"editWeight" + surgery.num} defaultValue={surgery.weight} onChange={(e) => { handleUpdateSurgeryInfo(surgery.num - 1, "weight", parseInt(e.target.value)) }} />
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className='px-0'>
                                                    {editSurgeries[surgery.num - 1][0] === false ?
                                                        <h4 style={{ color: "#444444" }}><strong>Height: </strong>{surgery.height ? surgery.height + " cm" : "None"}</h4>
                                                        :
                                                        <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                            <Form.Label style={{ color: "#444444", fontWeight: 'bold' }} htmlFor="editHeight" as="h4">Height:</Form.Label>
                                                            <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="number" id="editHeight" defaultValue={surgery.height} onChange={(e) => { handleUpdateSurgeryInfo(surgery.num - 1, "height", parseInt(e.target.value)) }} />
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className='px-0'>
                                                    {editSurgeries[surgery.num - 1][0] === false ?
                                                        <h4 style={{ color: "#444444" }}><strong>Bra Size: </strong>{surgery.bra_size ? surgery.bra_size : "None"}</h4>
                                                        :
                                                        <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                            <Form.Label style={{ color: "#444444" }} htmlFor={"editBraSize" + surgery.num} as="h4"><strong>Bra Size: </strong></Form.Label>
                                                            <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="number" id={"editBraSize" + surgery.num} defaultValue={surgery.bra_size} onChange={(e) => { handleUpdateSurgeryInfo(surgery.num - 1, "bra_size", parseInt(e.target.value)) }} />
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className='px-0'>
                                                    {editSurgeries[surgery.num - 1][0] === false ?
                                                        <h4 style={{ color: "#444444" }}><strong>Bra Cup: </strong>{surgery.bra_cup ? surgery.bra_cup : "None"}</h4>
                                                        :
                                                        <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                            <Form.Label style={{ color: "#444444" }} htmlFor={"editBraCup" + surgery.num} as="h4"><strong>Bra Cup: </strong></Form.Label>
                                                            <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="text" id={"editBraCup" + surgery.num} defaultValue={surgery.bra_cup} onChange={(e) => { handleUpdateSurgeryInfo(surgery.num - 1, "bra_cup", e.target.value) }} />
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className='px-0'>
                                                {editSurgeries[surgery.num - 1][0] === false ?
                                                    <h4 style={{ color: "#444444" }}><strong>Cancer Side: </strong>{appState.patient.surgeries_info[surgery.num - 1].cancer_side === 1 ? "Left" : appState.patient.surgeries_info[surgery.num - 1].cancer_side === 2 ? "Right" : appState.patient.surgeries_info[surgery.num - 1].cancer_side === 3 ? "Bilateral" : "Not Defined"}</h4>
                                                    :
                                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                        <Form.Label style={{ color: "#444444", fontWeight: 'bold' }} htmlFor="editCancerSide" as="h4">Cancer Side:</Form.Label>
                                                        <Select
                                                            isMulti
                                                            options={options4}
                                                            className="basic-multi-select w-100"
                                                            classNamePrefix="select"
                                                            name={"editCancerSide"}
                                                            defaultValue={defaultCancerSideValues}
                                                            onChange={(e) => {
                                                                newCancerSides.length = 0
                                                                e.map((aux) => { newCancerSides.push(parseInt(aux.value)) })
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                }
                                            </Row>
                                            <Row className='px-0 my-2' id={'surgicalIndicationField' + surgery.num}>
                                                {editSurgeries[surgery.num - 1][0] === false ?
                                                    <h4 style={{ color: "#444444" }}><strong>Surgical Indication: </strong>{appState.patient.surgeries_info[surgery.num - 1].surgical_indication === 1 ? "Cancer" :
                                                        appState.patient.surgeries_info[surgery.num - 1].surgical_indication === 2 ? "Prophylatic" :
                                                            appState.patient.surgeries_info[surgery.num - 1].surgical_indication === 3 ? "Improvement" :
                                                                null
                                                    }
                                                    </h4>
                                                    :
                                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                        <Form.Label style={{ color: "#444444" }} htmlFor={"editSurgicalIndication" + surgery.num} as="h4"><strong>Surgical Indication: </strong></Form.Label>
                                                        <Form.Select name={"surgical_indication_" + surgery.num} style={{ maxWidth: '20vw' }} className='mx-2' id={"editSurgicalIndication" + surgery.num} defaultValue={editSurgeries[surgery.num - 1][1]} onChange={(e) => { let aux = editSurgeries.slice(); aux[surgery.num - 1][1] = parseInt(e.target.value); setEditSurgeries(aux); handleUpdateSurgeryInfo(surgery.num - 1, "surgical_indication", parseInt(e.target.value)); }}>
                                                            <option value="null">---------</option>
                                                            <option value="1">Cancer</option>
                                                            <option value="2">Prophylatic</option>
                                                            <option value="3">Improvement</option>
                                                        </Form.Select>
                                                    </div>
                                                }
                                            </Row>
                                            <Row className='px-0 my-2'>

                                                {editSurgeries[surgery.num - 1][0] === false ?
                                                    <h4 style={{ color: "#444444" }}><strong>Surgery Types: </strong>
                                                        {
                                                            surgery.surgery_type.map((type) => {
                                                                return <p key={parseInt(type)}>
                                                                    {
                                                                        parseInt(type) === 1 ? "C1 - Conservative Surgery" :
                                                                            parseInt(type) === 2 ? "C2 - Conservative Surgery with bilateral reduction" :
                                                                                parseInt(type) === 3 ? "C3 - Conservative Surgery with LD or LICAC/TDAP flaps" :
                                                                                    parseInt(type) === 4 ? "C4 - Conservative Surgery with bilateral implants" :
                                                                                        parseInt(type) === 5 ? "M1 - Mastectomy with unilateral reconstruction with implant" :
                                                                                            parseInt(type) === 6 ? "M2 - Mastectomy with unilateral reconstruction with autologous flap" :
                                                                                                parseInt(type) === 7 ? "M3 - Mastectomy with bilateral reconstruction with implants" :
                                                                                                    parseInt(type) === 8 ? "M4 - Mastectomy with bilateral reconstruction with autologous flaps" :
                                                                                                        parseInt(type) === 9 ? "M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)" :
                                                                                                            parseInt(type) === 10 ? "M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction" :
                                                                                                                parseInt(type) === 11 ? "M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction" :
                                                                                                                    parseInt(type) === 12 ? "M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)" :
                                                                                                                        parseInt(type) === 13 ? "P1 - Fat Grafting" :
                                                                                                                            parseInt(type) === 14 ? "P2 - Scar Revision" :
                                                                                                                                parseInt(type) === 15 ? "P3 - Nipple Reconstruction" :
                                                                                                                                    parseInt(type) === 16 ? "P4a - Unilateral Delayed Reconstruction with autolugous flap" :
                                                                                                                                        parseInt(type) === 17 ? "P4b - Bilateral Delayed Reconstruction with autolugous flap" :
                                                                                                                                            parseInt(type) === 18 ? "P5a - Unilateral Delayed Reconstruction with implant" :
                                                                                                                                                parseInt(type) === 19 ? "P5b - Bilateral Delayed Reconstruction with implant" :
                                                                                                                                                    parseInt(type) === 20 ? "P6a - Unilateral Capsulectomy and implant replacement" :
                                                                                                                                                        parseInt(type) === 21 ? "P6b - Bilateral Capsulectomy and implant replacement" :
                                                                                                                                                            parseInt(type) === 22 ? "P7a - Uniloateral Capsulectomy and implant removal" :
                                                                                                                                                                parseInt(type) === 23 ? "P7b - Biloateral Capsulectomy and implant removal" :
                                                                                                                                                                    parseInt(type) === 24 ? "P8 - Reduction" :
                                                                                                                                                                        null
                                                                    }

                                                                </p>
                                                            })
                                                        }
                                                    </h4>
                                                    :
                                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                        <Form.Label style={{ color: "#444444" }} htmlFor={"editSurgeryType" + surgery.num} as="h4"><strong>Surgery Type: </strong></Form.Label>
                                                        {
                                                            editSurgeries[surgery.num - 1][1] === 1 ?
                                                                <Select
                                                                    isMulti
                                                                    options={options1}
                                                                    className="basic-multi-select w-100"
                                                                    classNamePrefix="select"
                                                                    defaultValue={defaultSurgeryValues}
                                                                    onChange={
                                                                        (e) => {
                                                                            newSurgeryTypes.length = 0
                                                                            e.map((aux) => { newSurgeryTypes.push(parseInt(aux.value)) })
                                                                        }}
                                                                />

                                                                :
                                                                editSurgeries[surgery.num - 1][1] === 2 ?
                                                                    <Select
                                                                        isMulti
                                                                        options={options2}
                                                                        className="basic-multi-select w-100"
                                                                        classNamePrefix="select"
                                                                        defaultValue={defaultSurgeryValues}
                                                                        onChange={
                                                                            (e) => {
                                                                                newSurgeryTypes.length = 0
                                                                                e.map((aux) => { newSurgeryTypes.push(parseInt(aux.value)) })
                                                                            }}
                                                                    />
                                                                    :
                                                                    editSurgeries[surgery.num - 1][1] === 3 ?

                                                                        < Select
                                                                            isMulti
                                                                            options={options3}
                                                                            className="basic-multi-select w-100"
                                                                            classNamePrefix="select"
                                                                            defaultValue={defaultSurgeryValues}
                                                                            onChange={
                                                                                (e) => {
                                                                                    newSurgeryTypes.length = 0
                                                                                    e.map((aux) => { newSurgeryTypes.push(parseInt(aux.value)) })
                                                                                }}
                                                                        />
                                                                        :
                                                                        null
                                                        }
                                                    </div>
                                                }

                                            </Row>
                                            <Row className='px-0 my-2'>
                                                {editSurgeries[surgery.num - 1][0] === false ?
                                                    <h4 style={{ color: "#444444" }}><strong>Surgery Date: </strong>{surgery.date}</h4>
                                                    :
                                                    <div className='mt-3' style={{ display: 'inline-flex' }}>
                                                        <Form.Label style={{ color: "#444444" }} htmlFor={"editSurgeryDate" + surgery.num} as="h4"><strong>Surgery Date: </strong></Form.Label>
                                                        <Form.Control className='mx-2' style={{ maxWidth: '10vw' }} type="date" id={"editSurgeryDate" + surgery.num} defaultValue={surgery.date} onChange={(e) => { handleUpdateSurgeryInfo(surgery.num - 1, "date", e.target.value) }} />
                                                    </div>
                                                }
                                            </Row>

                                            <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>

                                            <Container className='mt-5'>
                                                <h2 className='mt-3' style={{ color: "#444444" }}>Photos</h2>

                                                <Container>
                                                    <Row xs={1} md={3} className='g-2'>
                                                        {
                                                            surgery.images_properties.map((propertie) => {
                                                                return (
                                                                    <Col key={propertie[0]}>
                                                                        <Card>
                                                                            <Card.Header className="text-center card-header-text" as="h5">
                                                                                Classification: {getClassificationText(propertie[7])}
                                                                                {propertie[8] === null ?
                                                                                    null
                                                                                    :
                                                                                    propertie[8] === 1 ?
                                                                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Subjective Classification</Tooltip>}>
                                                                                            <i className="fas fa-circle" aria-hidden="true" style={{ color: 'red' }} />
                                                                                        </OverlayTrigger>
                                                                                        :
                                                                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Objective Classification</Tooltip>}>
                                                                                            <i className="fas fa-circle" aria-hidden="true" style={{ color: 'blue' }} />
                                                                                        </OverlayTrigger>
                                                                                }
                                                                            </Card.Header>
                                                                            {
                                                                                propertie[6] !== null ?
                                                                                    <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + propertie[6]} width="250" height="250" />
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <Card.Body>
                                                                                <div className='my-2 text-center'>
                                                                                    {
                                                                                        (propertie['2']) ?
                                                                                            <span>Pre-surgery</span> :
                                                                                            (propertie['3'] && propertie['4'] && propertie['5']) ?
                                                                                                <span>Post-surgery | Follow-up time: {propertie['4']} years and {propertie['5']} months</span> :
                                                                                                (propertie['3'] && propertie['4'] && !propertie['5']) ?
                                                                                                    <span>Post-surgery | Follow-up time: {propertie['4']} years</span> :
                                                                                                    (propertie['3'] && !propertie['4'] && propertie['5']) ?
                                                                                                        <span>Post-surgery | Follow-up time: {propertie['5']} months</span> :
                                                                                                        <span>Post-surgery | Follow-up time: less than 1 month</span>
                                                                                    }
                                                                                </div>
                                                                                <div className='text-center'>
                                                                                    <Button className='FilledButton' onClick={() => history.push({ pathname: generatePath("/patient/:id/surgery/:surgery_date/photos/:date", { id: appState.patient.id, date: propertie['0'], surgery_date: surgery.date }), state: { patient_id: appState.patient.id, date: propertie['0'], page: page_info, institutional_number: appState.patient.institutional_number, pre_surgery: propertie['2'], surgery_date: surgery.date } })} ><i className="fa fa-images" aria-hidden="true" ></i> {propertie['0']} ({propertie['1']}) </Button>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </Row>
                                                    <Button className='FilledButton my-3 mx-1' onClick={() => history.push({ pathname: generatePath("/patient/:id/upload", { id: patient_id }), state: { patient: appState.patient, page: page_info, surgery_date: surgery.date } })}><i className="fa fa-upload" aria-hidden="true"></i> Upload Photos </Button>
                                                </Container>

                                            </Container>
                                            <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                                        </Container>
                                    </Tab>

                                ))
                            }
                        </Tabs>

                    </Container>
                </Container>

                <div className='addPatient-div'>
                    <Button variant="outline-primary" className="deletePatient-button my-auto" onClick={() => handleShow()}><i className="fa fa-trash" aria-hidden="true"></i> Delete Patient </Button>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Patient {patient_id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this patient?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => deletePatient()}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </Container >

        )
    }*/
}
export default withRouter(PatientProfilePage)