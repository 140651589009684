import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import AuthContext from '../context/AuthContext'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withRouter } from "react-router-dom";
import history from '../history';
import jwt_decode from "jwt-decode"

const RegisterPage = () => {
  const [errors, setErrors] = useState({})

  const [username, setUsername] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [password2, setPassword2] = useState(null)
  const [team, setTeam] = useState(null)
  const [registerTeam, setRegisterTeam] = useState(false)
  const [isAdmin, setIsAdmin] = useState(null)

  let handleUsernameChange = (value) => {setUsername(value)}
  let handleFirstNameChange = (value) => {setFirstName(value)}
  let handleLastNameChange = (value) => {setLastName(value)}
  let handlePasswordChange = (value) => {setPassword(value)}
  let handlePassword2Change = (value) => {setPassword2(value)}
  let handleEmailChange = (value) => {setEmail(value)}

  const SECRET_KEY = '6iwNHCBlEO2hzHl'

  let token = null
  let decoded = null

  let url = null

  if (window.location.href.includes("localhost")) {
    url = "localhost"
  }
  else {
    url = "194.117.27.135"
  }

  let registerUser = async (e) => {
    e.preventDefault()
    let response = await fetch('https://breloai-backend.inesctec.pt/api/register/', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'username': username, 'first_name': firstName, 'last_name': lastName, 'email': email, 'password': password, 'password2': password2, 'team': team, 'is_admin': isAdmin })
    })
    let data = await response.json()
    if (response.status === 200) {
      history.push("/login")
    }
    else if (response.status === 400 && data.username) {
      setErrors({ username: data.username })
    }
    else if (response.status === 400 && data.password) {
      setErrors({ password: data.password })
    }
    else if (response.status === 400 && data.email) {
      setErrors({ email: data.email })
    }
  }

  useEffect( () => {
    if (window.location.hash !== null && window.location.hash !== "") {
      token = window.location.hash.split("#")[1]
      let time =  Date.now()
  
      decoded = jwt_decode(token, SECRET_KEY)
      if (decoded.exp * 1000 < time) {
        history.push("/login")
      }
      else {
        setEmail(decoded.email)
        setTeam(decoded.team_name)
        setIsAdmin(decoded.is_admin)
        setRegisterTeam(true)
      }
    }
  }, [])

  return (
    <div className='register-form-container my-auto'>
      <span>Sign Up @ WebBCCT.core</span>
      <Form className='registerForm' onSubmit={registerUser}>
        <Form.Group className="register">
          <Form.Group className='mb-3'>
            <Form.Label>Username</Form.Label>
            <Form.Control name="username" type="text" placeholder="Enter your username" style={{ marginBottom: 0 }} onChange={(e) => handleUsernameChange(e.target.value)} isInvalid={!!errors.username} />
            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            <Form.Text className="text-muted" style={{ fontSize: "0.7vw" }}>Letters, digits and @/./+/-/_ only.</Form.Text>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>First Name</Form.Label>
            <Form.Control name="first_name" type="text" placeholder="Enter your first name" onChange={(e) => handleFirstNameChange(e.target.value)} />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control name="last_name" type="text" placeholder="Enter your last name" onChange={(e) => handleLastNameChange(e.target.value)} />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Email</Form.Label>
            <Form.Control name="email" type="text" placeholder="Enter your email" disabled={registerTeam} defaultValue={email} onChange={(e) => handleEmailChange(e.target.value)} isInvalid={!!errors.email} />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" placeholder="Enter your password" style={{ marginBottom: 0 }} onChange={(e) => handlePasswordChange(e.target.value)} isInvalid={!!errors.password} />
            <Form.Text className="text-muted" style={{ marginBottom: 0, fontSize: "0.7vw" }}>Your password must contain at least 8 characters. </Form.Text>
            <Form.Text className="text-muted" style={{ fontSize: "0.7vw" }}>Your password can't be entirely numeric.</Form.Text>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Password confirmation</Form.Label>
            <Form.Control type="password" name="password2" placeholder="Confirm your password" style={{ marginBottom: 0 }} onChange={(e) => handlePassword2Change(e.target.value)} isInvalid={!!errors.password} />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            <Form.Text className="text-muted" style={{ fontSize: "0.7vw" }}>Enter the same password as before, for verification.</Form.Text>
          </Form.Group>
          <Form.Control name="team" type="hidden" placeholder="Enter the Team name" disabled={true} defaultValue={team}/>
          <Button className="register-Button mb-3" variant="primary" type="submit">Sign Up</Button>
          <p className="text-muted text-center" style={{ fontSize: "0.7vw" }}>Already Have An Account? <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { history.push("/login") }}>Log in!</span></p>
        </Form.Group>
      </Form>
    </div>
  )
}

export default withRouter(RegisterPage)
