import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import AuthContext from '../context/AuthContext'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withRouter } from "react-router-dom";
import history from '../history';
import { useLocation, generatePath } from "react-router-dom";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const ResetPasswordPage = () => {
    const [errors, setErrors] = useState({})

    const location = useLocation()

    let path = location.pathname.split("/")
    let id = path[path.length - 2]
    let token = path[path.length - 1]

    let url = null
    if (window.location.href.includes("localhost")) {
        url = "localhost"
    }
    else {
        url = "194.117.27.135"
    }

    let resetPassword = async (e) => {
        e.preventDefault()
        if (e.target.password.value != e.target.password2.value) {
            setErrors({ 'password': 'The passwords do not match!' })
        }
        else {
            let response = await fetch('https://breloai-backend.inesctec.pt/api/resetPasswordConfirmation/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'token': token, 'id': id, 'password': e.target.password.value })
            })
            if (response.status === 200) {
                history.push("/")
            }
        }
    }

    return (
        <div className='login-form-container'>
            <span>Reset Password</span>
            <Form className='my-1' onSubmit={resetPassword}>
                <Form.Group className="mx-5">
                    <Form.Label>Password</Form.Label>
                    <Form.Control className='my-2' name="password" type="password" placeholder="Enter your new password" isInvalid={!!errors.password} />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control className='my-2' type="password" name="password2" placeholder="Confirm your new password" isInvalid={!!errors.password} />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    <Button className="authenticate-Button my-3" variant="primary" type="submit">
                        Submit
                    </Button>
                </Form.Group>
            </Form>
        </div>
    )
}

export default withRouter(ResetPasswordPage)
