import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container';
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath, useLocation } from "react-router-dom";
import history from '../history';
import { FileUploader } from "react-drag-drop-files";
import axios from 'axios';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import Col from "react-bootstrap/esm/Col";
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/esm/Image';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const fileTypes = ["JPG", "PNG", "jpeg", "png", "jpg", "tiff", "tif", "bmp"];

const TeamDetailsPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)
    const [errors, setErrors] = useState({})

    let username = user.username

    const location = useLocation()

    let team_name = location.state

    const [appState, setAppState] = useState({
        isLoading: true,
        details: {},
    })

    const [newUserAdmin, setNewUserAdmin] = useState(false)
    const [newUserEmail, setNewUserEmail] = useState("")

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)

    const [show2, setShow2] = useState([false, null]);
    const handleClose2 = () => setShow2([false, null]);
    const handleShow2 = (user_info) => setShow2([true, user_info]);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [showToast, setShowToast] = useState(false);

    const [image, setImage] = useState(null)
    const handleImageChange = async (file) => {
        setImage(file)
        let info = new FormData()
        info.append('image', file)

        await axios.put('https://breloai-backend.inesctec.pt/api/team/' + team_name + '/picture', info, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })

        getTeamDetails()
    }

    let getTeamDetails = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/team/' + team_name, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setAppState({
                isLoading: false,
                details: data,
            })
        }
    }

    useEffect(() => {
        getTeamDetails()
    }, [])

    const updateTeamUser = async (user, value) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/team/' + team_name, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: JSON.stringify({
                "user_id": user,
                "is_admin": value
            })
        })
        let data = await response
        if (response.status === 200) {
            getTeamDetails()
        }
    }

    const removeTeamUser = async (user) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/team/' + team_name, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: JSON.stringify({
                "user_id": user,
                "is_admin": null
            })
        })
        let data = await response
        if (response.status === 200) {
            getTeamDetails()
        }
        handleClose2()
    }

    const deleteTeam = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/team/' + team_name + '/delete', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
        })
        let data = await response
        if (response.status === 200) {
            handleClose()
            history.push("/teams")
        }
    }

    const addUserTeam = async () => {
        if (newUserEmail === "" || newUserEmail === undefined || newUserAdmin === null) {
            setErrors({ email: "User email cannot be blank!" })
        }
        else {

            let response = await fetch('https://breloai-backend.inesctec.pt/api/team/' + team_name + '/add/' + newUserEmail, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access,
                },
                body: JSON.stringify({ 'is_admin': newUserAdmin, })
            })
            let data = await response.json()
            if (response.status === 200) {
                getTeamDetails()
                setShowToast(true)
                setShow3(false)
            }
            else if (response.status === 400 && data.email) {
                setErrors({ email: data.email })
            }
        }
    }

    if (appState.isLoading === true) {
        return (
            <Container className='text-center'>
                <div style={{ marginTop: '22.5%', }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Container>
        )
    }
    else {
        return (
            <Container>
                <ToastContainer className="mt-5" position="top-center">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg='success'>
                    <Toast.Header>
                        <strong className="me-auto">User Added</strong>
                    </Toast.Header>
                    <Toast.Body>
                        An email has been sent to {newUserEmail} with instructions on how to join the platform.
                    </Toast.Body>
                </Toast>
            </ToastContainer>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => history.push(generatePath("/teams"))}>Teams Page</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Team {team_name} Details
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Container className='content-container'>
                    <div className='addPatient-div'>
                        <Button variant="danger" disabled={!appState.details.is_admin} onClick={handleShow}><i className="fa fa-trash" aria-hidden="true"></i> Delete Team</Button>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Team</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete this team?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={() => deleteTeam()}>
                                    Confirm
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <Container className='text-center'>
                        <Image src={'https://breloai-backend.inesctec.pt' + appState.details.image} className="responsive-image my-3" height="200"></Image>
                        {appState.details.is_admin ?
                            <Container className="file-uploader-container">
                                <Form>
                                    <FileUploader multiple={false} handleChange={handleImageChange} name="file" types={fileTypes} />
                                </Form>
                            </Container>
                            :
                            null
                        }
                    </Container>

                    <Container className='text-center my-4'>
                        <h2>Members of the {team_name} team</h2>
                        {appState.details.is_admin ?
                            <Container>
                                <Row className='responsiveRow'>
                                    <Col className='mt-4'>
                                        <Button variant="outline-primary" className="addPatient-button" onClick={handleShow3}><i className="fa fa-user" aria-hidden="true"></i> Add User</Button>
                                    </Col>
                                </Row>

                                <Modal show={show3} onHide={handleClose3}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className='responsiveRow'>Add User</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row className='responsiveRow'>
                                            <Form.Group>
                                                <Form.Label>Email:</Form.Label>
                                                <Form.Control name="newUserEmail" type="text" onChange={(e) => setNewUserEmail(e.target.value)} isInvalid={errors.email} />
                                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className='my-3'>
                                            <Button variant={newUserAdmin ? 'success' : 'danger'} onClick={() => setNewUserAdmin(!newUserAdmin)}> Administrator? </Button>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="danger" onClick={handleClose3}>
                                            Cancel
                                        </Button>
                                        <Button variant="success" onClick={() => addUserTeam()}>
                                            Confirm
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Container>

                            :
                            null
                        }

                        <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>

                        <Container className='my-4'>
                            {
                                Object.entries(appState.details.users).map(user => {
                                    const [user_id, user_info] = user
                                    return (
                                        <Row key={user_id} className='my-3 responsiveRow'>
                                            <Col>
                                                <Card body style={{ width: "100%", display: '' }} key={user_id}>
                                                    Username: {user_info[0]}
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Button className='my-2' variant={user_info[1] ? 'success' : 'danger'} disabled={username === user_info[0] || !appState.details.is_admin} onClick={() => updateTeamUser(user_id, !user_info[1])}>
                                                    Administrator?
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button className='my-2' variant='danger' disabled={username === user_info[0] || !appState.details.is_admin} onClick={() => handleShow2(user_id)}>Remove User</Button>

                                                <Modal show={show2[0]} onHide={handleClose2}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Remove User</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        Are you sure you want to remove this user from this team?
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleClose2}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="danger" onClick={() => removeTeamUser(show2[1])}>
                                                            Confirm
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </Col>
                                        </Row>
                                    )
                                }
                                )
                            }
                        </Container>
                    </Container>
                </Container >
            </Container>

        )
    }
}

export default withRouter(TeamDetailsPage)