import React, { useContext, useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { useLocation, generatePath } from "react-router-dom";
import AuthContext from '../context/AuthContext';

import history from '../history';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Spinner from 'react-bootstrap/Spinner';

const ChampalimaudLoginPage = () => {
  let { updateToken, url  } = useContext(AuthContext)

  const location = useLocation()

  const [error, setError] = useState([false, null])

  let champalimaudLogin = async () => {
    if (location.search != "" && location.search != null) {
      let vars = location.search.split("&")
      let email = null
      let patient = null
      if (vars.length != 1) {
        email = vars[0].split("=")[1]
        patient = vars[1].split("=")[1]
      }
      else {
        email = location.search.split("=")[1]
      }

      let response = await fetch('https://breloai-backend.inesctec.pt/api/champalimaud/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'email': email, 'patient': patient })
      })
      let data = await response.json()
      if (response.status === 200) {
        if (patient != null) {
          let patient_id = data.split("#")[0].split("=")[1]
          await updateToken(data.split("#")[1]).then(() => {
            history.push({ pathname: generatePath("/patient/:id", { id: patient_id }), state: { id: patient_id} })
          })
        }
        else {
          await updateToken(data.split("#")[1]).then(() => {
            history.push("/")
          })
        }
      }
      else {
        setError([true, data['error']])
      }
    }
    else {

    }
  }


  useEffect(() => {
    champalimaudLogin()
  }, [])

  return (
    <div className='text-center align-items-center mt-5'>
      {
        error[0] === false ?
          <div>
            <h2>You will be redirected to your account!</h2>
            <Spinner animation="border" role="status" />
          </div>
          :
          <div>
            <h2>{error[1]}</h2>
            <h4>Try again later.</h4>
          </div>
      }
    </div>
  )
}

export default withRouter(ChampalimaudLoginPage)
